import { compose, withStateHandlers } from "recompose";
import {
  withScriptjs,
  withGoogleMap,
  GoogleMap,
  Marker
} from "react-google-maps";


const Map = compose(
  withStateHandlers(() => ({
    isMarkerShown: false,
    markerPosition: null
  }), {
    onMapClick: ({ isMarkerShown }) => (e, onChange) => {
      return {
        markerPosition: e.latLng,
        isMarkerShown: true,
      }

    },
  }),
  withScriptjs,
  withGoogleMap,
)
  (props => {
    
    return (
      <GoogleMap
      defaultZoom={8}
      defaultCenter={props.value}
      >
        {(props.value) && <Marker position={props.value} />}

      </GoogleMap>
    )
  }
  )

const MapView = (props) => {
  return (
    <div style={{ height: '100%' }}>
      <Map
        value={props.value}
        googleMapURL="https://maps.googleapis.com/maps/api/js?key=AIzaSyAlscCRkWJHgo_-d7b2D-O27BBunttjDPQ&v=3.exp&libraries=geometry,drawing,places"
        loadingElement={<div style={{ height: `100%` }} />}
        containerElement={<div style={{ height: `400px` }} />}
        mapElement={<div style={{ height: `100%` }} />}
      />
    </div>
  )
}

export default MapView