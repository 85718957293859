import React, { useEffect, useState } from "react";
import { useDelete, useQuery } from "react-admin";
import MetaTags from "react-meta-tags";
import {
  Card,
  CardBody,
  CardSubtitle,
  CardTitle,
  Container,
  Spinner,
} from "reactstrap";

import Breadcrumb from "../../Common/Breadcrumb";
import { capitalizeFirstLetter } from "helpers/common.ts";
import { capitalCase } from "capital-case";
import i18n from "i18next";
import Filters from "../Filters";

const TasksList = ({ resource, title, subtitle, children, filters, ...props }) => {
  const [
    destroy,
    { loading: deleteLoading, error: deleteError, data: deleteData },
  ] = useDelete();
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(10);
  const [sort, setSort] = useState({ field: "id", order: "DESC" });
  const [finalData, setFinalData] = useState([]);
  const [allWithData, setAllWithData] = useState(false);

  const allStatus = [
    { state: "blocked", id: 0 },
    { state: "in progress", id: 1 },
    { state: "waiting", id: 2 },
    { state: "done", id: 3 },
  ];

  const getList = status => {
    const { data, total, loading, error, refetch } = useQuery(
      {
        type: "getList",
        resource,
        payload: {
          pagination: { page, perPage: status === "done" ? 10 : perPage },
          sort: {
            field: status === "done" ? "end_at" : sort.field,
            order: sort.order,
          },
          filter: { status: status },
        },
      },
      [

      ]
    );

    return { data, total, loading, error, refetch };
  };


  const { data, total, loadingCount, error } = allStatus.reduce(
    (results, status) => {
      let {
        data: qData,
        total: qTotal,
        loading: qLoading,
        error: qError,
        refetch: qRefetch,
      } = getList(status.state);

      return {
        error: results.error || qError,
        total: results.total + qTotal,
        loadingCount: results.loadingCount - qLoading,
        data: [
          ...results.data,
          {
            loading: qLoading,
            data: qData,
            refetch: qRefetch,
            status: status.state,
          },
        ],
      };
    },
    { data: [], total: 0, loadingCount: allStatus.length, error: false }
  );


  useEffect(() => {
    if (loadingCount > 0 && !error) {
      setAllWithData(true)

      let check = true;
      data.map(status => {
        if (status.data === undefined)
          check = false;
      })

      setAllWithData(check)
      setFinalData([...data])
    }
    else setAllWithData(false)
  }, [loadingCount])


  return (
    <div className="page-content">
      <MetaTags>
        <title> {capitalCase(i18n.t(resource))} | Flex - Admin </title>
      </MetaTags>

      <Container fluid>
        <Breadcrumb
          title={i18n.t("management")}
          breadcrumbItem={capitalCase(i18n.t(resource))}
        />
        <Filters filters={filters}/>
        <Card>
          <CardBody>
            <CardTitle className="text-center">
              {" "}
              {i18n.t(title) ?? capitalizeFirstLetter(i18n.t(resource))}{" "}
            </CardTitle>
            <CardSubtitle className="mb-3"> {i18n.t(subtitle)} </CardSubtitle>

            {typeof children === "function" &&
              children({ finalData, setFinalData, allWithData, allStatus, error, loadingCount, resource, ...props })}
          </CardBody>
        </Card>
      </Container>
    </div>
  );
};

export default TasksList;
