import * as Yup from "yup";
import i18n from "i18n";


export const createFields = [
    {
        source: 'name',
        label: i18n.t('name'),
        validation: Yup.string().required(i18n.t("required_field")).max(255, i18n.t('too_long')),
    },
    {
        source: 'description',
        label: i18n.t('description'),
        validation: Yup.string().required(i18n.t("required_field")),
    },
    {
        source: 'location',
        label: i18n.t('location'),
        component: 'map',
        validation: Yup.object().shape({
            lat: Yup.string().required(i18n.t("required_field")),
            lng: Yup.string().required(i18n.t("required_field")),
          }),

    },
    {
        source: 'address',
        label: i18n.t('address'),
        validation: Yup.string().required(i18n.t("required_field")).max(255, i18n.t('too_long')),
    },
    {
        source: 'manager_id',
        label: i18n.t('manager'),
        component: 'select',
        type: 'remote',
        resource: 'users',
        optionLabel: 'name',
        optionValue: 'id',
        initialValue: ({manager: {id}}) => id,
        validation: Yup.number().required(i18n.t("required_field")),
    },
    {
        source: 'image',
        label: i18n.t('image'),
        type: 'file',
        component: 'file',
    },
    {
        source: 'items',
        label: i18n.t('items'),
        validation: Yup.array(),
        component: 'array',
        required: false,
        fields: [{
            source: 'id',
            label: i18n.t('item'),
            component: 'select',
            type: 'remote',
            resource: 'items',
            optionLabel: 'name',
            optionValue: 'id',
            initialValue: ({manager: {id}}) => id,
            validation: Yup.number().required(i18n.t("required_field")),
        }, {
            source: 'quantity',
            label: i18n.t('quantity'),
            validation: Yup.number().required(i18n.t("required_field")),
        },
            {
                source: 'limit',
                label: i18n.t('limit'),
                validation: Yup.number().required(i18n.t("required_field")),
            }],
    },
];
export const editFields = createFields;

export const columns = [
    {source: 'name', label: i18n.t('name'), sortable: true},
    {source: 'address', label: i18n.t('address'), sortable: true},
    {source: 'manager.name', label: i18n.t('manager')},
];


export const ViewOne = [
    {source: 'name', label: i18n.t('name'), sortable: true},
    {source: 'description', label: i18n.t('description'), sortable: true},
    {source: 'manager', component: 'object', target: 'name', label: i18n.t('manager')},
    {source: 'image', label: i18n.t("image"), component: 'image'},
    {source: 'address', label: i18n.t("address")},
    {source: 'items_total_count', label: i18n.t("items_total_count")},
    {source: 'location', component: 'map', label: i18n.t('address'), sortable: true},
    {
        source: "items", count: "total_items", component: 'table', label: i18n.t('items'), direction: 'column', columns: [
            {source: 'name', label: i18n.t('name'), sortable: true},
            {source: 'unit', label: i18n.t('unit'), sortable: true},
            {source: 'total_count', label: i18n.t('total_count'), sortable: true},
            {source: 'limit', label: i18n.t('limit'), sortable: true},
        ],
    },
]

export const filters = [];
export const title = i18n.t("warehouses");
export const subtitle = ""
