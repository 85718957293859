import React, { useState } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import i18n from "i18n";
import {
  Button,
  Spinner,
  Modal,
  ModalBody,
  ModalHeader,
  ModalFooter,
} from "reactstrap";
const CalculatorModal = ({modal,setModal,modalLoading,modalData,createPurchaseOrder, navigationData, show}) => {
    const columns = [
        {
          dataField: "name",
          text: i18n.t("name"),
        },
        {
          dataField: "unit",
          text: i18n.t("unit"),
        },
        {
          dataField: "order_quantity",
          text: i18n.t("order_quantity"),
        },
        {
          dataField: "available_quantity",
          text: i18n.t("available_quantity"),
        },
        {
          dataField: "required_quantity",
          text: i18n.t("required_quantity"),
        },
      ];
  return (
    <div>
      <Modal
        draggable={true}
        style={{ top: "20%" }}
        size="lg"
        isOpen={modal}
        toggle={() => setModal(!modal)}
      >
        <ModalHeader toggle={() => setModal(!modal)}>
          {i18n.t("statsify_order")}
        </ModalHeader>
        <ModalBody>
          {modalLoading ? (
            <div className="d-flex justify-content-center align-items-center ml-3">
              <Spinner color="primary" />
            </div>
          ) : (
            Array.isArray(modalData) && (
              <div
                style={{
                  paddingRight: "20px",
                  maxHeight: "400px",
                  overflowY: "auto",
                  overflowX: "hidden",
                }}
              >
                <BootstrapTable
                  key="name"
                  keyField="name"
                  remote
                  responsive
                  data={modalData}
                  columns={columns}
                  bordered={false}
                  striped={false}
                  headerWrapperClasses={"thead-light"}
                  onTableChange={console.log}
                  classes={"table table-wrap"}
                  bodyStyle={{ wordBreak: "break-all" }}
                  rowStyle={{ whiteSpace: "pre-wrap", cursor: "default" }}
                />
              </div>
            )
          )}
        </ModalBody>
        <ModalFooter className="d-flex justify-content-between">
          {show && <Button
            color="primary"
            onClick={e => createPurchaseOrder(e, navigationData)}
          >
            {i18n.t("new_purchase_order")}
          </Button>}
          <Button color="danger" onClick={() => setModal(!modal)}>
            {i18n.t("cancel")}
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
};

export default CalculatorModal;
