import { AxiosResponse, AxiosRequestConfig } from "axios";
import useAxios from "axios-hooks";
import toastr from "toastr";
import i18next from "i18next";

interface UseQueryValue {
  data: any;
  loading: boolean;
  error: any;
  response: any;

  refetch: () => void;
  manualCancel: () => void;
}

export default function useQuery<T>(
  url: string,
  params: Object = {},
  config: AxiosRequestConfig = {}
): UseQueryValue {
  const [{ data, loading, error, response }, refetch, manualCancel] = useAxios(
    {
      url,
      method: "GET",
      params,
      headers: {
        Authorization: `Bearer ${localStorage.token}`,
        "Access-Control-Allow-Origin": "no-cors",
      },
      ...config,
    },
    {
      useCache: false,
    }
  );
  if (error) {
    if (error.request.status === 401) {
      localStorage.removeItem("token");
      localStorage.removeItem("permissions");
      localStorage.removeItem("user");
      localStorage.removeItem("fcmToken");
      toastr.error(i18next.t("session_has_been_revoked"), "", { positionClass: 'toast-bottom-center' });
      window.location.href = "/login";
    }
  }
  return { data, loading, error, response, refetch, manualCancel };
}
