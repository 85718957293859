import Breadcrumb from "../../Common/Breadcrumb";
import {
  Card,
  CardBody,
  CardTitle,
  Col,
  Container,
  Row,
  Alert,
} from "reactstrap";
import MetaTags from "react-meta-tags";
import toastr from "toastr";
import { useHistory } from "react-router";
import i18n from "i18n";
import { useEffect, useState } from "react";
import CalculatorModal from "components/Tables/CalculatorModal";
import { connect } from "react-redux";
import { getItemsData } from "../../../store/purchaseOrder/actions";
import api from "api_helper"

const Create = props => {
  const { title, subtitle, resource, transform, children, getItemsData } =
    props;
  const [loading, setLoading] = useState(false)
  const[error, setError] = useState("")
  const [showButton, setShowButton] = useState(true)
  // const [create, { data, loading, error }] = useCreate(resource);
  const [modalData, setModalData] = useState(null);
  const [navigationData, setNavigationData] = useState(null);
  const [modal, setModal] = useState(false);

  const history = useHistory();

  const createPurchaseOrder = (e, row) => {
    let items = [];
    row.items.length > 0 &&
      row.items.map(e => {
        items.push({ id: e.item.id, order_quantity: e.required_quantity });
      });
    getItemsData(items);
    history.push("/purchase_orders/create");
  };

  const onSubmit = (values, actions) => {
    setLoading(true);
    api
      .post(process.env.REACT_APP_API_URL + `/${resource}`, transform(values))
      .then(response => {
        let r = 0;
        values= ""
        if (resource === "neediness/calculator") {
          setNavigationData(response.data);
          let data = [];
          response.data.items.map(e => {
            r += Number(e.required_quantity);
            data.push({
              name: e.item.name,
              unit: e.item.unit,
              order_quantity: e.order_quantity,
              available_quantity: e.available_quantity,
              required_quantity: e.required_quantity,
            });
          });
          setModalData(data);
          setShowButton(r !== 0)
          setModal(true);
        } else {
          toastr.success(i18n.t("successfully_added"), null, {
            positionClass: "toast-bottom-center",
          });
          history.push("/" + resource);
        }
      })
      .catch(error => {
        if (error.response.status === 422) {
          toastr.error(i18n.t(error.response.data.message), null, {
            positionClass: "toast-bottom-center",
          });
        } else if (error.status === 401) {
          localStorage.removeItem("token");
          localStorage.removeItem("permissions");
          localStorage.removeItem("user");
          localStorage.removeItem("fcmToken");
          toastr.error(i18n.t("session_has_been_revoked"), null, {
            positionClass: "toast-bottom-center",
          });
          history.push("/login");
        } else {
          toastr.error(i18n.t("something_went_wrong"), null, {
            positionClass: "toast-bottom-center",
          });
        }
      }).finally(()=>{
        setLoading(false)
      })
  };
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const { t } = i18n;
  return (
    <div className="page-content">
      <MetaTags>
        <title> {i18n.t(`create_${resource}`)} </title>
      </MetaTags>
      <Container fluid>
        <Row>
          <Col>
            <Breadcrumb
              title="create"
              breadcrumbItem={resource}
              resource={resource}
            />
          </Col>
        </Row>
        <Row>
          <Col>
            <Card>
              <CardBody>
                <CardTitle className="h4">
                  {i18n.t("create_new") + " " + i18n.t(resource)}
                </CardTitle>
                <p className="card-title-desc"> {i18n.t(subtitle)} </p>
                {typeof children === "function"
                  ? children({ onSubmit, t, loading, error })
                  : children}
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>

      <CalculatorModal
        modal={modal}
        setModal={setModal}
        modalLoading={false}
        modalData={modalData}
        createPurchaseOrder={createPurchaseOrder}
        navigationData={navigationData}
        show={showButton}
      />
    </div>
  );
};

Create.defaultProps = {
  transform: values => values,
};

export default connect(null, { getItemsData })(Create);
