import * as Yup from "yup";
import i18n from "i18n";
import classNames from "classnames"

export const createFields = [
    {
        source: 'order_id',
        label: i18n.t('purchase_order'),
        component: 'select',
        type: 'remote',
        resource: 'orders',
        optionLabel: 'name',
        optionValue: 'id',
        validation: Yup.number().required(),
    },
    {
        source: 'product_id',
        label: i18n.t('product'),
        component: 'select',
        type: 'remote',
        resource: 'products',
        optionLabel: 'name',
        optionValue: 'id',
        validation: Yup.number().required(),
    },
    {
        source: 'pipeline_id',
        label: i18n.t('pipeline'),
        component: 'select',
        type: 'remote',
        resource: 'pipelines',
        optionLabel: 'name',
        optionValue: 'id',
        validation: Yup.number().required(),
    },
    {
        source: 'deliver_date',
        label: i18n.t('deliver_date'),
        component: 'date',
        validation: Yup.date().required(),
    },
];
export const editFields = [
    {
        source: 'order_id',
        label: i18n.t('purchase_order'),
        component: 'select',
        type: 'remote',
        resource: 'orders',
        optionLabel: 'name',
        optionValue: 'id',
        disable: true, // TODO
        initialValue: ({ order: { id } }) => id,
        validation: Yup.number().required(),
    },
    {
        source: 'product_id',
        label: i18n.t('product'),
        component: 'select',
        type: 'remote',
        resource: 'products',
        optionLabel: 'name',
        optionValue: 'id',
        disable: true,
        initialValue: ({ product: { id } }) => id,
        validation: Yup.number().required(),
    },
    {
        source: 'pipeline_id',
        label: i18n.t('pipeline'),
        component: 'select',
        type: 'remote',
        resource: 'pipelines',
        optionLabel: 'name',
        optionValue: 'id',
        disable: true,
        initialValue: ({ pipeline: { id } }) => id,
        validation: Yup.number().required(),
    },
    {
        source: 'deliver_date',
        label: i18n.t('deliver_date'),
        component: 'date',
        validation: Yup.date().required(),
    },
];

export const columns = [
    { source: 'id', label: i18n.t('id'), sortable: true },
    { source: 'order.id', label: i18n.t('order') },
    { source: 'product.name', label: i18n.t('product') },
    { source: 'pipeline.name', label: i18n.t('pipeline') },
    { source: 'start_at', component: 'date' ,label: i18n.t('start_at') },
    { source: 'end_at', component: 'date', label: i18n.t('end_at') },
    { source: 'status', label: i18n.t('status')  , renderer: (status) => <div className={classNames("badge text-white",{
        "bg-success":status === "done",
        "bg-primary":status === "in progress",
        "bg-warning":status === "waiting",
        "bg-danger":status === "blocked",
    })}>
        {i18n.t(status)}
    </div> },
    {
        source: 'progress', label: i18n.t('progress'), renderer: (progress) => <div className="text-center">
            <small className="text-dark">{i18n.t(parseFloat(progress).toFixed(2))}%</small>
            <div className="progress">
                <div class="progress-bar" role="progressbar" style={{ "width": `${parseFloat(progress).toFixed(2)}%` }}
                className={classNames("progress-bar", {
                    "bg-danger": parseFloat(progress).toFixed(2) < 50,
                    "bg-warning": parseFloat(progress).toFixed(2) < 100 && parseFloat(progress).toFixed(2) >= 50,
                    "bg-success": parseFloat(progress).toFixed(2) == 100,
                })} aria-valuemin="0" aria-valuemax="100" />
            </div>
        </div>
    },
];


export const ViewOne = [
    { source: 'id', label: i18n.t('id'), sortable: true },
    { source: 'deliver_date',component: 'date', label: i18n.t('deliver_date'), sortable: true },
    { source: 'start_at', component: 'date' ,label: i18n.t('start_at'), sortable: true },
    { source: 'end_at', component: 'date', label: i18n.t('end_at'), sortable: true },
    { source: 'order', component: 'object', target: 'id', label: i18n.t('order') },
    { source: 'product', component: 'object', target: 'name', label: i18n.t('product') },
    { source: 'pipeline', component: 'object', target: 'name', label: i18n.t('pipeline') },
    { source: 'status', label: i18n.t('status') },
    { source: 'progress', label: i18n.t('progress'), component: 'progress' },
    {
        source: 'tasks', count: "total_tasks", component: 'table', direction: 'column', label: i18n.t('tasks'), columns: [
            { source: 'name', label: i18n.t('name'), sortable: true },
            { source: 'feedback', label: i18n.t('feedback'), sortable: true },
            { source: 'status', label: i18n.t('status'), sortable: true },
            { source: 'order', label: i18n.t('sequence_number') },
        ]
    },
]

export const filters = [
    {
        label: i18n.t('status'),
        source: 'status',
        component: 'select',
        optionText: 'label',
        container: 'filters',
        direction: 'column',
        options: [
            { id: 1, value: 'blocked', label: i18n.t('blocked') },
            { id: 2, value: 'waiting', label: i18n.t('waiting') },
            { id: 3, value: 'in progress', label: i18n.t('in progress') },
            { id: 4, value: 'done', label: i18n.t('done') },
        ]
    },
    {
        source: 'min_progress',
        label: i18n.t('progress'),
        direction: 'column',
        component: 'input',
        defaultValue: [0, 100],
        type: 'range',
        container: 'filters',
        validation: Yup.number(),
    },
    {
        source: 'deliver_date',
        direction: 'column',
        label: i18n.t('deliver_date'),
        component: 'date',
        type: 'range',
        container: 'filters',
    },
];

export const title = i18n.t("manufacture_orders");
export const subtitle = "";
