import {AxiosResponse, AxiosRequestConfig} from "axios";
import useAxios from "axios-hooks";
import {Identifier} from "react-admin";
import toastr from "toastr";
import i18n from "i18next"
interface UseDeleteValue {
    data: any;
    loading: boolean;
    error: any;
    response: any;
}

export default function useDelete<T>(
    resource: string = "",
    id: Identifier = 1,
    params: any = {},
    config: AxiosRequestConfig = {},
): UseDeleteValue {
    const [{data, loading, error, response}] = useAxios({
            url: resource + '/' + id,
            method: "DELETE",
            ...params,
            headers:{
                'Authorization': `Bearer ${localStorage.token}`,
                'Access-Control-Allow-Origin': 'no-cors',
              },
            ...config,
        },
        {
            useCache: false
        }
    );
    if (error) {
        if (error.request.status === 401) {
          localStorage.removeItem("token");
          localStorage.removeItem("permissions");
          localStorage.removeItem("user");
          localStorage.removeItem("fcmToken");
          toastr.error(i18n.t("session_has_been_revoked"), "", { positionClass: 'toast-bottom-center' });
          window.location.href = "/login";
        }
      }
    return {data, loading, error, response};
}
