import classes from "classnames";
import "flatpickr/dist/themes/material_blue.css"
import Flatpickr from "react-flatpickr"

const DateRangeFormik = props => {
    const {source, value, touched, error, type, disable, onChange, defaultValue} = props;

    return (
        <Flatpickr
            id={source}
            name={source}
            value={value}
            type={type}
            disable={disable}
            placeholder="dd M,yyyy" 
            className={classes("form-control d-block", {"is-touched": !!touched, "is-invalid": !!error})}
            defaultValue={defaultValue}
            onChange={(date, dateStr) => typeof onChange === 'function' && onChange(dateStr) && console.log(dateStr)}
            options={{
                mode: "range",
                dateFormat: "Y-m-d"
            }}
        />
    );
}

export default DateRangeFormik;