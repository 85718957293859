import React from "react";
import {Create, Edit, List, View} from "components/CRUD";
import {DataTable} from "components/Tables";
import FormBuilder from "components/FormBuilder";
import {columns, createFields, editFields, filters, subtitle, ViewOne} from "./stages.config";
import ViewMaker from "components/FormBuilder/ViewMaker";
import i18n from "i18n";

export const StagesList = props => (
    <List {...props} resource={props.resource} title="stages" filters={filters}>
        {({data, ...rest}) =>
            <DataTable resource={props.resource} data={data} columns={columns} {...rest}/>}
    </List>
);

export const StagesCreate = props => (
    <Create {...props}>
        {(data) => <FormBuilder fields={createFields} {...props} {...data} />}
    </Create>
);

export const StagesEdit = (props) => (
    <Edit title={i18n.t("edit")} {...props}>
        {(data) => <FormBuilder fields={editFields} {...props} {...data} />}
    </Edit>
);


export const StagesView = (props) => (
  <View title={i18n.t("view")} resource={props.resource} subtitle={subtitle} url="/stages" {...props}>
      {(props) => <ViewMaker fields={ViewOne} {...props}/>}
  </View>
);