import usFlag from "../assets/images/flags/us.jpg"
import syria from "../assets/images/flags/syria.jpg"

const languages = {
  ar: {
    label: "العربية",
    flag: syria,
  },
  en: {
    label: "English",
    flag: usFlag,
  },
}

export default languages
