import MetaTags from "react-meta-tags";
import React from "react";
import { useState } from "react";
import useAxios from "axios-hooks";
import { Label, Spinner } from "reactstrap";
import {
  Card,
  CardBody,
  Col,
  Container,
  Row,
  Alert,
  UncontrolledCollapse,
  Button,
} from "reactstrap";
import i18n from "i18n";
import "../components/Tables/datatables.scss";
import api from "api_helper";
import classNames from "classnames";
import { useHistory } from "react-router";
import toastr from "toastr";
import Dropzone from "react-dropzone";
import { Link } from "react-router-dom";

const Profile = props => {
  const [update, setUpdate] = useState(false);
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [newPassword, setNewPassword] = useState(null);
  const [confirmPassword, setConfirmPassword] = useState(null);
  const [image, setImage] = useState("");
  const [errors, setErrors] = useState(false);
  const history = useHistory();
  const [selectedFiles, setSelectedFiles] = useState([]);

  if (!localStorage?.token) {
    history.push("/login");
  }

  const url =
    process.env.REACT_APP_API_URL +
    "/users/" +
    JSON.parse(localStorage?.user).id;
  const [{ data, loading, error, response }, refetch, manualCancel] = useAxios(
    {
      url,
      method: "GET",
      headers: {
        Authorization: `Bearer ${localStorage.token}`,
        "Access-Control-Allow-Origin": "no-cors",
      },
    },
    {
      useCache: false,
    }
  );
  if (loading)
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          width: "100%",
          height: "100vh",
        }}
      >
        <Spinner color="primary" />
      </div>
    );

  if (error) {
    if (error.request.status === 401) {
      localStorage.removeItem("token");
      localStorage.removeItem("permissions");
      localStorage.removeItem("user");
      localStorage.removeItem("fcmToken");
      toastr.error(i18n.t("session_has_been_revoked"), null, {
        positionClass: "toast-bottom-center",
      });
      history.push("/login");
    }

    return <Alert color="danger">{error.message}</Alert>;
  }

  if (response) {
    localStorage.setItem(
      "user",
      JSON.stringify({
        id: response.data.id,
        email: response.data.email,
        image: response.data.image,
        name: response.data.name,
        role: response.data.name,
      })
    );
  }

  const handleUpdate = e => {
    setName(data.name);
    setEmail(data.email);
    setUpdate(!update);
    setErrors(false);
  };

  const handleSubmit = () => {
    if (newPassword && newPassword !== confirmPassword) {
      toastr.error(i18n.t("passwords_don't_match"), null, {
        positionClass: "toast-bottom-center",
      });
    } else {
      api
        .put("/users/" + JSON.parse(localStorage?.user).id, {
          name,
          email,
          image,
          password,
          new_password: newPassword,
        })
        .then(res => {
          refetch();
          setUpdate(false);
          setErrors(false);
          setImage("");
          setName("");
          setEmail("");
          setPassword("");
          setNewPassword("");
          setConfirmPassword("");
        })
        .catch(error => {
          toastr.error(
            i18n.t(
              Object.values(error?.response?.data?.errors)[0] ||
                error?.response?.data?.message ||
                error.message
            ),
            null,
            {
              positionClass: "toast-bottom-center",
            }
          );
          setErrors(true);
        });
    }
  };

  const handleAcceptedFiles = files => {
    files.map(file =>
      Object.assign(file, {
        preview: URL.createObjectURL(file),
        formattedSize: formatBytes(file.size),
      })
    );

    const file = files[0];
    if (!file) {
      this.setState({ invalidImage: "Please select image." });
      return false;
    }

    if (!file.name.match(/\.(jpg|jpeg|png|gif)$/)) {
      this.setState({ invalidImage: "Please select valid image." });
      return false;
    }

    const reader = new FileReader();
    reader.onloadend = () => {
      setImage(reader.result);
    };
    reader.readAsDataURL(file);

    setSelectedFiles(files);
  };

  const formatBytes = (bytes, decimals = 2) => {
    if (bytes === 0) return "0 Bytes";
    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
  };

  const renderInput = () => {
    return (
      <React.Fragment>
        <Label style={{ marginRight: "5px" }} source="image" className="text">
          {" "}
          {i18n.t("image")}:{" "}
        </Label>
        <input
          id="image"
          name="image"
          value={image}
          type="text"
          className="form-control"
          hidden
        />
      </React.Fragment>
    );
  };

  const renderImage = () => {
    return (
      <>
        {renderInput()}
        <Dropzone onDrop={acceptedFiles => handleAcceptedFiles(acceptedFiles)}>
          {({ getRootProps, getInputProps }) => (
            <div className="dropzone">
              <div className="dz-message needsclick" {...getRootProps()}>
                <input {...getInputProps()} multiple={false} />
                <div className="mb-3">
                  <i className="display-4 text-muted bx bxs-cloud-upload" />
                </div>
                <h4>{i18n.t("file_uploader_inner_text")}</h4>
              </div>
            </div>
          )}
        </Dropzone>
        <div className="dropzone-previews mt-3" id="file-previews">
          {selectedFiles.map((f, i) => {
            return (
              <Card
                className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete"
                key={i + "-file"}
              >
                <div className="p-2">
                  <Row className="align-items-center">
                    <Col className="col-auto">
                      <img
                        data-dz-thumbnail=""
                        height="80"
                        className="avatar-sm rounded bg-light"
                        alt={f.name}
                        src={f.preview}
                      />
                    </Col>
                    <Col>
                      <Link to="#" className="text-muted font-weight-bold">
                        {f.name}
                      </Link>
                      <p className="mb-0">
                        <strong>{f.formattedSize}</strong>
                      </p>
                    </Col>
                  </Row>
                </div>
              </Card>
            );
          })}
        </div>
      </>
    );
  };

  return (
    <div className="page-content">
      <MetaTags>
        <title>Profile</title>
      </MetaTags>
      <Container fluid>
        <Row>
          <Col xl={12}>
            <Card>
              <CardBody>
                <div className="mb-3 d-flex justify-content-between align-items-center">
                  <div></div>
                  {!update && (
                    <div>
                      <button
                        onClick={handleUpdate}
                        className={"btn btn-primary"}
                      >
                        {i18n.t("update")}
                      </button>
                    </div>
                  )}
                </div>
                {errors && (
                  <small className="text-center text-danger">
                    Values are not valid
                  </small>
                )}
                <div
                  className="d-flex justify-content-between "
                  style={{ marginBottom: "30px" }}
                >
                  <div className="flex-1" style={{ width: "50%" }}>
                    <div
                      className={`d-flex mt-3 ${
                        update ? "flex-column" : "flex-row"
                      }`}
                      style={{ fontSize: "16px" }}
                    >
                      <Label
                        style={{ marginRight: "5px" }}
                        source="name"
                        className="text"
                      >
                        {" "}
                        {i18n.t("name")}:{" "}
                      </Label>
                      {update ? (
                        <input
                          type="text"
                          value={name}
                          onChange={e => setName(e.target.value)}
                          className="form-control"
                        />
                      ) : (
                        <div style={{ margin: "0px 10px" }}>{data.name}</div>
                      )}
                    </div>
                    <div
                      className={`d-flex mt-3 ${
                        update ? "flex-column" : "flex-row"
                      }`}
                      style={{ fontSize: "16px" }}
                    >
                      <Label
                        style={{ marginRight: "5px" }}
                        source="email"
                        className="text"
                      >
                        {" "}
                        {i18n.t("email")}:{" "}
                      </Label>
                      {update ? (
                        <input
                          type="email"
                          value={email}
                          onChange={e => setEmail(e.target.value)}
                          className="form-control"
                        />
                      ) : (
                        <div style={{ margin: "0px 10px" }}>{data.email}</div>
                      )}
                    </div>
                    <div
                      className="d-flex mt-3"
                      style={{ fontSize: "16px", flexDirection: "column" }}
                    >
                      {update ? renderImage() : null}
                    </div>

                    {update && (
                      <>
                        <Button
                          color="primary"
                          id="toggler"
                          style={{
                            marginBottom: "1rem",
                          }}
                        >
                          {i18n.t("change_password")}
                        </Button>
                        <UncontrolledCollapse toggler="#toggler">
                          <div className="mt-3 ">
                            <div className="mt-3 ">
                              <div
                                className={`d-flex mt-3 ${
                                  update ? "flex-column" : "flex-row"
                                }`}
                                style={{ fontSize: "16px" }}
                              >
                                <Label
                                  style={{ marginRight: "5px" }}
                                  source="password"
                                  className="text"
                                >
                                  {" "}
                                  {i18n.t("old_password")} :
                                </Label>
                                <input
                                  autocomplete="off"
                                  type="password"
                                  value={password}
                                  onChange={e => setPassword(e.target.value)}
                                  className="form-control"
                                />
                              </div>
                            </div>
                            <div
                              className={`d-flex mt-3 ${
                                update ? "flex-column" : "flex-row"
                              }`}
                              style={{ fontSize: "16px" }}
                            >
                              <Label
                                style={{ width: "200px" }}
                                source="password"
                                className="text"
                              >
                                {" "}
                                {i18n.t("new_password")} :{" "}
                              </Label>
                              <input
                                type="password"
                                autoComplete="off"
                                value={newPassword}
                                onChange={e => setNewPassword(e.target.value)}
                                className="form-control"
                              />
                            </div>

                            <div
                              className={`d-flex mt-3 ${
                                update ? "flex-column" : "flex-row"
                              }`}
                              style={{ fontSize: "16px" }}
                            >
                              <Label
                                style={{ marginRight: "5px" }}
                                source="confirmPassword"
                                className="text"
                              >
                                {i18n.t("confirm_password")} :{" "}
                              </Label>
                              <input
                                autocomplete="off"
                                type="password"
                                value={confirmPassword}
                                onChange={e =>
                                  setConfirmPassword(e.target.value)
                                }
                                className="form-control"
                              />
                            </div>
                          </div>
                        </UncontrolledCollapse>
                      </>
                    )}

                    <div className="d-flex mt-3 " style={{ fontSize: "16px" }}>
                      <Label source="role" className="text">
                        {i18n.t("role")}:{" "}
                      </Label>
                      <div style={{ margin: "0px 10px" }}>{data.role.name}</div>
                    </div>
                  </div>

                  <div className="text-center">
                    <img
                      src={data.image}
                      style={{ width: "150px", height: "150px" }}
                      className="img-fluid rounded"
                    />
                  </div>
                </div>

                {update && (
                  <div className="mb-3 d-flex justify-content-between align-items-center">
                    <>
                      <div>
                        {update && (
                          <button
                            onClick={handleSubmit}
                            className="btn btn-danger"
                          >
                            {i18n.t("submit")}
                          </button>
                        )}
                      </div>

                      <button
                        onClick={handleUpdate}
                        className={classNames({
                          "btn btn-secondary": update,
                          "btn btn-primary": !update,
                        })}
                      >
                        {i18n.t("undo")}
                      </button>
                    </>
                  </div>
                )}
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default Profile;
