import classes from "classnames";
import {Component} from "react";
import {Card, Col, Row} from "reactstrap";
import {Link} from "react-router-dom";
import Dropzone from "react-dropzone"
import i18n from "i18next";
import logo from "assets/images/logo.svg";

class FileUploaderFormik extends Component {
    constructor(props) {
        super(props)
        this.handleAcceptedFiles = this.handleAcceptedFiles.bind(this)
        this.state = {
            selectedFiles: [],
        }
        props.onChange('');
    }

    handleAcceptedFiles = files => {
        files.map(file =>
            Object.assign(file, {
                preview: URL.createObjectURL(file),
                formattedSize: this.formatBytes(file.size),
            })
        )

        const file = files[0];
        if (!file) {
            this.setState({invalidImage: 'Please select image.'});
            return false;
        }

        if (!file.name.match(/\.(jpg|jpeg|png|gif)$/)) {
            this.setState({invalidImage: 'Please select valid image.'});
            return false;
        }

        const reader = new FileReader();
        reader.onloadend = () => {
            const {onChange} = this.props;
            onChange(reader.result);
        }
        reader.readAsDataURL(file);

        this.setState({selectedFiles: files})
    }

    formatBytes = (bytes, decimals = 2) => {
        if (bytes === 0) return "0 Bytes"
        const k = 1024
        const dm = decimals < 0 ? 0 : decimals
        const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"]

        const i = Math.floor(Math.log(bytes) / Math.log(k))
        return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i]
    }

    renderInput = () => {
        const {source, value, touched, error, type, disabled, onChange, defaultValue} = this.props;
        return (
            <input
                id="imageUpload"
                name="image"
                value="s"
                type="text"
                disabled={disabled}
                hidden
                className={classes("form-control", {"is-touched": !!touched, "is-invalid": !!error})}
                onChange={(e) => typeof onChange === 'function' && this.handleChange(e.target.value)}
            />
        );
    }


    render = () => {
        return (
            <>
                {this.renderInput(this.props)}
                <Dropzone
                    onDrop={acceptedFiles =>
                        this.handleAcceptedFiles(acceptedFiles)
                    }
                >
                    {({getRootProps, getInputProps}) => (
                        <div className="dropzone">
                            <div
                                className="dz-message needsclick"
                                {...getRootProps()}
                            >
                                <input {...getInputProps()} multiple={false} />
                                <div className="mb-3">
                                    <i className="display-4 text-muted bx bxs-cloud-upload"/>
                                </div>
                                <h4>{i18n.t('file_uploader_inner_text')}</h4>
                            </div>
                        </div>
                    )}
                </Dropzone>
                <div
                    className="dropzone-previews mt-3"
                    id="file-previews"
                >
                    {this.state.selectedFiles.map((f, i) => {
                        return (
                            <Card
                                className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete"
                                key={i + "-file"}
                            >
                                <div className="p-2">
                                    <Row className="align-items-center">
                                        <Col className="col-auto">
                                            <img
                                                data-dz-thumbnail=""
                                                height="80"
                                                className="avatar-sm rounded bg-light"
                                                alt={f.name}
                                                src={f.preview}
                                            />
                                        </Col>
                                        <Col>
                                            <Link
                                                to="#"
                                                className="text-muted font-weight-bold"
                                            >
                                                {f.name}
                                            </Link>
                                            <p className="mb-0">
                                                <strong>{f.formattedSize}</strong>
                                            </p>
                                        </Col>
                                    </Row>
                                </div>
                            </Card>
                        )
                    })}
                </div>
            </>
        )
    }
}

export default FileUploaderFormik;