import DayJS from 'react-dayjs';
import i18n from "i18n"
const Dates = ({ value }) => {
    return (
        <div>
            {value ? value?.length > 10 ? <DayJS format="MM-DD-YYYY HH:mm:ss" >{value?? i18n.t("data_should_be_fulifilled")}</DayJS> : <DayJS format="MM-DD-YYYY" >{value}</DayJS>: <div className="text-danger">{i18n.t("data_should_be_fulfilled")}</div>}
        </div>
    )
}

export default Dates;