import ReactDOM from "react-dom"
import * as serviceWorker from "./serviceWorker"
import { Provider } from "react-redux"
import reportWebVitals from "./reportWebVitals";
import AppCRUD from "./AppCRUD";
import { createTheme, ThemeProvider } from "@material-ui/core";
import { axios } from "network";
import { configure } from "axios-hooks";
import "./i18n"
import {adminStore} from "store";

// Change behavior of Axios
configure({ axios });

const baseTheme = createTheme();


if ("serviceWorker" in navigator) {
  navigator.serviceWorker
    .register("./firebase-messaging-sw.js")
    .then(function(registration) {
      console.log("Registration successful, scope is:", registration.scope);
    })
    .catch(function(err) {
      console.log("Service worker registration failed, error:", err);
    });
}

const app = (
  <Provider store={adminStore}>
    <ThemeProvider theme={baseTheme}>
      <AppCRUD crossorigin  />
    </ThemeProvider>
  </Provider>
)

ReactDOM.render(app, document.getElementById("root"))
serviceWorker.unregister()

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
