import * as Yup from "yup";
import i18n from "i18n";

export const createFields = [
    {
        source: 'name',
        label: i18n.t('name'),
        validation: Yup.string().required(i18n.t("required_field")).max(50, i18n.t('too_long')),
    },
    {
        source: 'description',
        label: i18n.t('description'),
        validation: Yup.string().required(i18n.t("required_field")),
    },
    {
        source: 'stages',
        label: i18n.t('stages'),
        validation: Yup.array().required(i18n.t("required_field")).of(
            Yup.object().shape({
              id: Yup.number().typeError(i18n.t("this_field_must_be_a_number")).required(i18n.t("required_field")),
              manager_id: Yup.number().typeError(i18n.t("this_field_must_be_a_number")).required(i18n.t("required_field")),
            })
          ),
        component: 'array',
        fields: [{
            source: 'id',
            label: i18n.t('stage'),
            component: 'select',
            type: 'remote',
            resource: 'stages',
            optionLabel: 'name',
            optionValue: 'id',
            initialValue: ({ stage: { id } }) => id,
        }, {
            source: 'manager_id',
            label: i18n.t('manager'),
            component: 'select',
            type: 'remote',
            resource: 'users',
            optionLabel: 'name',
            distinct: true,
            optionValue: 'id',
            initialValue: ({ manager: { id } }) => id,
        }],
    }
];
export const editFields = createFields;

export const columns = [
    { source: 'name', label: i18n.t('name'), sortable: true },
];

export const ViewOne = [
    { source: 'name', label: i18n.t('name') },
    { source: 'description', label: i18n.t('description') },
    {
        source: 'stages',count: "total_stages" ,label: i18n.t('stages'), direction: 'column', component: 'table', columns: [
            { source: 'name', label: i18n.t('name'), sortable: true },
            { source: 'description', label: i18n.t('description'), sortable: true },
            { source: 'manager.name', label: i18n.t('manager_name'), sortable: true },
            { source: 'order', label: i18n.t('order'), sortable: true, renderer: (value) => <div>{value + 1}</div> },
        ]
    }
];


export const filters = [
];

export const [title, subtitle] = [
    i18n.t("pipelines"),
    ""
];