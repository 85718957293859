import PropTypes from "prop-types";
import ViewMake from "./components/View/ViewMake";
import api from "../../api_helper";
import { useHistory } from "react-router";
import { Link } from "react-router-dom";
import toastr from "toastr";
import i18n from "i18n";
import Swal from "sweetalert2";

const ViewMaker = props => {
  const {
    values,
    resource,
    validation,
    title,
    fields,
    loading,
    onSubmit,
    onChange,
    transformer,
  } = props;

  const history = useHistory();

  const editUrl = `${resource}/:id/edit`;
  const deleteUrl = `/${resource}/${values.id}`;

  const editable = values?.editable ?? false;
  const deletable = values?.deletable ?? false;

  const handleEditClick = () => {};

  const handleDeleteClick = e => {
    if (deletable) {
      Swal.fire({
        title: i18n.t("are_you_sure") + "?",
        text: i18n.t("you_will_not_be_able_to_recover_this"),
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: i18n.t("yes_delete_it") + "!",
        cancelButtonText: i18n.t("no_keep_it"),
      }).then(result => {
        if (result.isConfirmed) {
          api
            .delete(deleteUrl)
            .then(() => {
              toastr.success(
                values.name + " " + i18n.t("successfully_deleted"),
                null,
                { positionClass: "toast-bottom-center" }
              );
              history.push(`/${resource}`);
            })
            .catch(error => {
              toastr.error(
                values.name + " " + i18n.t("could_not_be_deleted"),
                null,
                { positionClass: "toast-bottom-center" }
              );
            });
        } else if (result.dismiss === Swal.DismissReason.cancel) {
          toastr.info(i18n.t("delete_canceled"), null, {
            positionClass: "toast-bottom-center",
          });
        }
      });
    } else {
      toastr.error(i18n.t("could_not_be_deleted"), null, {
        positionClass: "toast-bottom-center",
      });
    }
  };

  return (
    <div>
      <div>
        <Link
          className="text-primary"
          to={"/" + resource}
          style={
            localStorage?.I18N_LANGUAGE === "ar"
              ? { marginLeft: "20px" }
              : { marginLeft: 0 }
          }
          title={"return to " + resource + " page"}
        >
          <i
            className={`mdi mdi-arrow-${
              localStorage?.I18N_LANGUAGE === "ar" ? "right" : "left"
            }-bold font-size-24`}
            onClick={() => history.push("/" + resource)}
          />
        </Link>

        {resource !== "tasks" && (
          <Link
            className="text-success"
            style={{ marginLeft: "20px" }}
            to={editUrl.replace(":id", values.id)}
          >
            <i
              className="mdi mdi-pencil font-size-18"
              onClick={handleEditClick}
            />
          </Link>
        )}

        <Link className="text-danger" style={{ marginLeft: "20px" }} to="#">
          <i
            className="mdi mdi-delete font-size-18"
            onClick={handleDeleteClick}
          />
        </Link>
      </div>

      {fields.map(({ source, label, count, type, ...rest }, index) => {
        const total = parseInt(values?.[count] ?? 0);
        return (
          <ViewMake
            title={title}
            key={index}
            className="mt-3"
            value={values[source]}
            total={total}
            data={values}
            resource={resource}
            source={source}
            label={label ?? source}
            type={type}
            {...rest}
          />
        );
      })}
    </div>
  );
};

ViewMaker.defaultProps = {
  transformer: value => value,
  disable: false,
};

ViewMaker.propTypes = {
  fields: PropTypes.array.isRequired,
  transformer: PropTypes.func,
};

export default ViewMaker;
