import * as Yup from "yup";
import { useFormik } from "formik";
import {  Form } from "reactstrap";
import PropTypes from "prop-types";
import { FormikField } from "./components";
import FieldContainerFormik from "./components/FieldContainerFormik";
import InnerFormContainerFormik from "./components/InnerFormContainerFormik";
import ActionsContainer from "./components/actions-container/ActionsContainer";
import { adminStore } from "../../store";
import {connect} from "react-redux"
import { clearData } from "../../store/purchaseOrder/actions";


const getInitValue = (field, values, resource, method) => {
    if(resource === 'purchase_orders' && method === 'create') {
        if (typeof field.initialValue === 'function'){
            if(field.source === "items" && adminStore.getState().purchaseOrder?.items?.length > 0) {
                return field.initialValue({
                 item: adminStore.getState().purchaseOrder.items
                })
            }
        }
    }
    if (field.initialValue) {
        if (typeof field.initialValue === 'function')
            if (values)
                return field.initialValue(values);
            else
                return null;
        return field.initialValue;
    }

    if (values && field.source)
        return values[field.source];

    return null;
}

const FormBuilder = (props) => {
    const {values: _values, validation, fields: _fields,resource, loading, onSubmit, onChange, clearData,transformer, method, id} = props;
    const fields = _fields.filter((field) => !!field.source);
    const values = transformer(_values);
    const initialValues = fields.reduce((result, field) => ({
        ...result,
        [field.source]: getInitValue(field, values, resource, method) ?? "",
    }), {});
    const validationSchema = validation ?? Yup.object().shape(fields.filter(field => !!field.validation).reduce((result, field) => ({
        ...result,
        [field.source]: field.validation,
    }), {}));

    const formik = useFormik({
        initialValues,
        validationSchema,
        onSubmit: (values, actions) => {
            if(resource === 'purchase_orders'){
                clearData();
            }

            if (typeof onSubmit === 'function') {
                const modifiedValues = {...values};
                if (modifiedValues?.items == "") {
                    modifiedValues.items = []
                }
                if (!!values?.permissions) {
                    const permissions = []
                    modifiedValues.permissions.map(e => {
                        permissions.push({
                            id: e.id
                        })
                    })
                    modifiedValues.permissions = permissions;
                }

                if (values?.deliver_date) {
                    const deliver = modifiedValues.deliver_date.split("to")
                    modifiedValues.min_deliver_date = deliver[0]
                    modifiedValues.max_deliver_date = deliver[1]
                }
                if (values?.order_date) {
                    const order = modifiedValues.order_date.split("to")
                    modifiedValues.min_order_date = order[0]
                    modifiedValues.max_order_date = order[1]
                }

                if (values?.min_progress) {
                    modifiedValues.max_progress = values.min_progress[1]
                    modifiedValues.min_progress = values.min_progress[0]
                }
                if (values?.products_count) {
                    modifiedValues.max_products_count = values.min_progress[1]
                    modifiedValues.min_products_count = values.min_progress[0]
                }
                onSubmit(modifiedValues, actions);
            }

        },
    });
    const handleChange = (name, value, actions) => {
        if (name === "permissions") {
            value.checked = !value.checked
            if (value.checked) {
                formik.setFieldValue(name, [...formik.values.permissions, value]);
            } else {
                const removeValue = formik.values.permissions;
                const index = removeValue.findIndex(e => e.id === value.id);
                removeValue.splice(index, 1)
                formik.setFieldValue(name, removeValue);
            }
        } else {
            formik.setFieldValue(name, value);
        }

        if (typeof onChange === 'function')
            onChange(formik.values, actions);

        if (typeof actions.onChange === 'function')
            actions.onChange(value);
    };

    const onCancel = (e, values) => {
        formik.handleReset('');
        formik.handleSubmit('');
    }

    const handleSubmit = () => {
        formik.handleSubmit('');
    };

    const {fieldContainer, innerContainer, actionsContainer} = props;
    const {enableActionButtons} = props;
    const {type} = props;
    return (
        <Form onSubmit={formik.handleSubmit}>
            <InnerFormContainerFormik container={innerContainer}>
                {props.title === "purchase_orders" && values?.status === "in progress" && props?.Customfields ? props.Customfields.map(({
                                                                                                                                            source,
                                                                                                                                            label,
                                                                                                                                            onChange,
                                                                                                                                            disabled,
                                                                                                                                            ...rest
                                                                                                                                        }) => (
                    <FieldContainerFormik key={source} container={fieldContainer}>
                        <FormikField
                            id={id}
                            resource={resource}
                            source={source}
                            label={label}
                            data={formik.values}
                            value={formik.values[source]}
                            error={formik.errors[source]}
                            touched={formik.touched[source]}
                            onChange={(value) => handleChange(source, value, {onChange})}
                            disable={disabled}
                            {...rest}
                        />
                    </FieldContainerFormik>
                )) : fields.map(({source, label, onChange, disabled, ...rest}) => (
                    <FieldContainerFormik key={source} container={fieldContainer}>
                        <FormikField
                            resource={resource}
                            id={id}
                            source={source}
                            label={label}
                            value={formik.values[source]}
                            error={formik.errors[source]}
                            touched={formik.touched[source]}
                            onChange={(value) => handleChange(source, value, {onChange})}
                            disable={disabled}
                            {...rest}
                        />
                    </FieldContainerFormik>
                ))}

                <ActionsContainer type={type} loading={loading} handleSubmit={handleSubmit} onCancel={onCancel}
                                  container={actionsContainer} {...props} />

            </InnerFormContainerFormik>
        </Form>
    );
};

FormBuilder.defaultProps = {
    transformer: value => value,
    disable: false,
};

FormBuilder.propTypes = {
    fields: PropTypes.array.isRequired,
    transformer: PropTypes.func,
};

const mapStateToProps = (state) => ({
    
})



export default connect (mapStateToProps, {clearData})(FormBuilder);