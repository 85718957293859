import MetaTags from "react-meta-tags";
import {
  Card,
  CardBody,
  CardSubtitle,
  CardTitle,
  Col,
  Container,
  Row,
} from "reactstrap";
import { useEffect } from "react";

import Breadcrumb from "../../Common/Breadcrumb";
import toastr from "toastr";
import Swal from "sweetalert2";
import Filters from "../Filters";
import i18n from "i18next";
import { useDataTable } from "../../../hooks";
import { useDelete } from "react-admin";
import { useHistory } from "react-router";

const deleteItem = (history, resource, row, id, { destroy, refetch }) => {
  if (row.deletable) {
    Swal.fire({
      title: i18n.t("are_you_sure") + i18n.t("?"),
      text: i18n.t("you_will_not_be_able_to_recover_this"),
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: i18n.t("yes_delete_it") + "!",
      cancelButtonText: i18n.t("no_keep_it"),
    }).then(result => {
      if (result.isConfirmed) {
        destroy(resource, id, null, {
          onFailure: response => {
            if (response.status === 422) {
              let message = response.message;

              if (response.body.errors["id"])
                message = response.body.errors["id"][0];

              toastr.error(i18n.t(message), null, {
                positionClass: "toast-bottom-center",
              });
            } else if (response.status === 401) {
              localStorage.removeItem("token");
              localStorage.removeItem("permissions");
              localStorage.removeItem("user");
              localStorage.removeItem("fcmToken");
              toastr.error(i18n.t("session_has_been_revoked"), null, {
                positionClass: "toast-bottom-center",
              });
              history.push("/login");
            } else {
              toastr.error(i18n.t("something_went_wrong"), null, {
                positionClass: "toast-bottom-center",
              });
            }
          },
          onSuccess: response => {
            refetch();
            toastr.success(i18n.t("successfully_deleted"), null, {
              positionClass: "toast-bottom-center",
            });
          },
        });
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        toastr.info(i18n.t("delete canceled"), null, {
          positionClass: "toast-bottom-center",
        });
      }
    });
  } else {
    toastr.error(i18n.t("could_not_be_deleted"), null, {
      positionClass: "toast-bottom-center",
    });
  }
};

const FeedbackWrapper = ({ loading, error, children, ...props }) => {
  if (error) return <p style="color: red"> {error} </p>;

  if (typeof children === "function") return children(props);

  return children;
};

const List = ({ resource, title, subtitle, url, children, ...props }) => {
  const history = useHistory();
  const {
    data,
    loading,
    error,
    total,
    page,
    pageSize,
    sort,
    filters,
    searchQuery,

    update,
    destroy: d,
    refetch,

    setPage,
    setPageSize,
    setFilters,
    setSort,
    setSearchQuery,
  } = useDataTable({ resource });

  const handleClearSearch = () => setSearchQuery("");
  const handleSort = (value, order) => setSort({ field: value, order: order });

  const onFilterSubmit = values => {
    setFilters(values);
  };

  const [
    destroy,
    { loading: deleteLoading, error: deleteError, data: deleteData },
  ] = useDelete();

  const handleSearchChange = q => {
      setSearchQuery(q);
  };
  const handlePageChange = page => setPage(page);
  const handlePageSizeChange = pageSize => setPageSize(pageSize);
  const handleDelete = (row, id) =>
    deleteItem(history, resource, row, id, { destroy, refetch });

  const render = () => {
    if (typeof children === "function")
      return children({
        resource,
        data,
        loading,
        error,
        total,
        refetch,

        page,
        pageSize,
        searchQuery,
        sort,

        update,
        destroy,

        onDelete: handleDelete,
        onSort: handleSort,
        onSearch: handleSearchChange,
        onPageChange: handlePageChange,
        onPageSizeChange: handlePageSizeChange,

        ...props,
      });

    return children;
  };
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className="page-content">
      <MetaTags>
        <title> {i18n.t(resource)} </title>
      </MetaTags>

      <Container fluid>
        <Breadcrumb breadcrumbItem={resource} title="" resource={resource} />
        <Filters filters={props.filters} t={i18n.t} onSubmit={onFilterSubmit} />
        <Card>
          <CardBody>
            <CardTitle> {i18n.t(title ?? resource)} </CardTitle>
            <CardSubtitle className="mb-3"> {i18n.t(subtitle)} </CardSubtitle>
            {render()}
          </CardBody>
        </Card>
      </Container>
    </div>
  );
};

export default List;
