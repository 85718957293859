import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";

import { connect } from "react-redux";

import ReactDrawer from "react-drawer";

// Reactstrap
import { Dropdown, DropdownToggle } from "reactstrap";

// Import menuDropdown
import LanguageDropdown from "../CommonForBoth/TopbarDropdown/LanguageDropdown";
import NotificationDropdown from "../CommonForBoth/TopbarDropdown/NotificationDropdown";
import ProfileMenu from "../CommonForBoth/TopbarDropdown/ProfileMenu";

//i18n
import { withTranslation } from "react-i18next";

// Redux Store
import {
  showRightSidebarAction,
  toggleLeftmenu,
  changeSidebarType,
} from "../../store/actions";

const Header = props => {
  const [search, setsearch] = useState(false);
  const [megaMenu, setmegaMenu] = useState(false);
  const [socialDrp, setsocialDrp] = useState(false);

  const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
  const [position, setPosition] = useState();
  const [open, setOpen] = useState(false);

  const toggleTopDrawer = () => {
    setPosition("right");
    setOpen(!open);
  };

  const onDrawerClose = () => {
    setOpen(false);
  };
  function tToggle() {
    var body = document.body;
    body.classList.toggle("vertical-collpsed");
    body.classList.toggle("sidebar-enable");
  }

  return (
    <React.Fragment>
      {localStorage?.token ? (
        <div>
          <header id="page-topbar">
            <div className="navbar-header">
              <div className="d-flex">
                {isMobile && (
                  <button
                    type="button"
                    onClick={() => {
                      tToggle();
                    }}
                    className="btn btn-sm px-3 font-size-16 header-item "
                    id="vertical-menu-btn"
                  >
                    <i className="fa fa-fw fa-bars" />
                  </button>
                )}
                <Dropdown
                  className="dropdown-mega d-none d-lg-block ms-2"
                  isOpen={megaMenu}
                  toggle={() => {
                    setmegaMenu(!megaMenu);
                  }}
                >
                  {isMobile && (
                    <DropdownToggle
                      className="btn header-item "
                      caret
                      tag="button"
                    ></DropdownToggle>
                  )}
                </Dropdown>
              </div>
              <div className="d-flex">
                <LanguageDropdown />
                <NotificationDropdown />
                <ProfileMenu />
              </div>
            </div>
          </header>
          <ReactDrawer
            open={open}
            position={position}
            onClose={onDrawerClose}
          ></ReactDrawer>{" "}
        </div>
      ) : (
        <div></div>
      )}
    </React.Fragment>
  );
};

Header.propTypes = {
  changeSidebarType: PropTypes.func,
  leftMenu: PropTypes.any,
  leftSideBarType: PropTypes.any,
  showRightSidebar: PropTypes.any,
  showRightSidebarAction: PropTypes.func,
  t: PropTypes.any,
  toggleLeftmenu: PropTypes.func,
};

const mapStatetoProps = state => {
  const { layoutType, showRightSidebar, leftMenu, leftSideBarType } =
    state.Layout;
  return { layoutType, showRightSidebar, leftMenu, leftSideBarType };
};

export default connect(mapStatetoProps, {
  showRightSidebarAction,
  toggleLeftmenu,
  changeSidebarType,
})(withTranslation()(Header));
