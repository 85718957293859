import React from "react";
import classes from 'classnames';
import {Col, Row} from "reactstrap";

const DefaultContainer = ({children, className, ...rest}) =>
    <React.Fragment {...rest}>
        {children}
    </React.Fragment>;

const getContainer = (container) => {
    if (React.isValidElement(container))
        return container;

    switch (container) {
        case 'row':
            return Row;

        case 'col':
            return Col;

        default:
            return DefaultContainer;
    }
};

const InnerFormContainerFormik = ({key, children, container, ...rest}) => {
    if (typeof container === 'function')
        return container({children});

    const Container = getContainer(container);

    return (
        <Container key={key} {...rest}>
            {children}
        </Container>
    );
};

export default InnerFormContainerFormik;