import * as Yup from "yup";
import i18n from "i18n";
import classNames from "classnames"
import { adminStore } from "store";

export const createFields = [
    {
        source: 'order_date',
        label: i18n.t('order_date'),
        component: 'timestamp',
        validation: Yup.date().required(i18n.t("required_field")),
    },
    {
        source: 'warehouse_id',
        label: i18n.t('warehouse'),
        component: 'select',
        type: 'remote',
        resource: 'warehouses',
        optionLabel: 'name',
        optionValue: 'id',
        initialValue: ({warehouse: {id}}) => id,
        validation: Yup.number().required(i18n.t("required_field")),
    },
    {
        source: 'items',
        label: i18n.t('items'),
        validation: Yup.array().required(i18n.t("required_field")).of(
            Yup.object().shape({
              id: Yup.number().typeError(i18n.t("this_field_must_be_a_number")).required(i18n.t("required_field")),
              order_quantity: Yup.number().typeError(i18n.t("this_field_must_be_a_number")).required(i18n.t("required_field")),
            })
          ),
        component: 'array',
        initialValue:  ({item}) => item,
        fields: [{
            source: 'id',
            label: i18n.t('item'),
            component: 'select',
            type: 'remote',
            resource: 'items',
            optionLabel: 'name',
            optionValue: 'id',
        }, {
            source: 'order_quantity',
            label: i18n.t('order_quantity'),
            optionLabel: 'order_quantity',
            initialValue: ({item: {order_quantity}}) => order_quantity,
        },],
    }
];


export const editFields = [
    {
        source: 'order_date',
        label: i18n.t('order_date'),
        component: 'timestamp',
        validation: Yup.date().required(i18n.t("required_field")),
    },
    {
        source: 'warehouse_id',
        label: i18n.t('warehouse'),
        component: 'select',
        type: 'remote',
        resource: 'warehouses',
        optionLabel: 'name',
        optionValue: 'id',
        initialValue: ({warehouse: {id}}) => id,
        validation: Yup.number().required(i18n.t("required_field")),
    },
    {
        source: 'items',
        label: i18n.t('items'),
        validation: Yup.array().required(i18n.t("required_field")).of(
            Yup.object().shape({
              id: Yup.number().typeError(i18n.t("this_field_must_be_a_number")).required(i18n.t("required_field")),
              order_quantity: Yup.number().typeError(i18n.t("this_field_must_be_a_number")).required(i18n.t("required_field")),
            })
          ),
        component: 'array',
        fields: [{
            source: 'id',
            label: i18n.t('item'),
            component: 'select',
            type: 'remote',
            resource: 'items',
            optionLabel: 'name',
            optionValue: 'id',
        }, {
            source: 'order_quantity',
            label: i18n.t('order_quantity'),
            optionLabel: 'order_quantity',
        },],
    }
];

export const CustomEditFields = [
    {
        source: 'order_date',
        label: i18n.t('order_date'),
        component: 'timestamp',
        
        disabled: true,
    },
    {
        source: 'deliver_date',
        label: i18n.t('deliver_date'),
        component: 'timestamp',
        placeholder: "Please add a date",
        validation: Yup.date().required(i18n.t("required_field")),
    },
    {
        source: 'warehouse_id',
        label: i18n.t('warehouse'),
        component: 'select',
        type: 'remote',
        resource: 'warehouses',
        optionLabel: 'name',
        optionValue: 'id',
        initialValue: ({warehouse: {id}}) => id,
        disabled: true,
    },
    {
        source: 'items',
        label: i18n.t('items'),
        component: 'array',
        validation: Yup.array().required(i18n.t("required_field")).of(
            Yup.object().shape({
              id: Yup.number().typeError(i18n.t("this_field_must_be_a_number")).required(i18n.t("required_field")),
              order_quantity: Yup.number().typeError(i18n.t("this_field_must_be_a_number")).required(i18n.t("required_field")),
              available_quantity: Yup.number().typeError(i18n.t("this_field_must_be_a_number")).required(i18n.t("required_field")),
            })
          ),
        fields: [
            {
                disabled: true,
                source: 'id',
                label: i18n.t('item'),
                component: 'select',
                type: 'remote',
                resource: 'items',
                optionLabel: 'name',
                optionValue: 'id',
                initialValue: ({item: {id}}) => id,
            }, {
                source: 'order_quantity',
                label: i18n.t('order_quantity'),
                disabled: true,
            },
            {
                source: 'available_quantity',
                label: i18n.t('available_quantity'),
            },
        ],
    }
];
export const columns = [
    { source: 'id', label: i18n.t('id'), sortable: true },
    { source: 'warehouse.name', label: i18n.t('warehouse'), sortable: true },
    { source: 'approve_date',component: 'date', label: i18n.t('approve_or_decline_date') },
    { source: 'order_date', component: 'date', label: i18n.t('order_date') },
    { source: 'items', label: i18n.t('items'), renderer: (items) => `${items.length}` },
    { source: 'status', label: i18n.t('status'), renderer: (status) => <div className={classNames("badge text-white",{
        "bg-success":status === "done",
        "bg-danger":status === "suspended",
        "bg-primary":status === "in progress",
        "bg-warning":status === "waiting",
    })}>
        {i18n.t(status)}
    </div> },
];

export const ViewOne = [
    { source: 'id', label: i18n.t('id') },
    { source: 'warehouse', component: 'object', target: 'name', label: i18n.t('warehouse') },
    { source: 'order_date', component: 'date', label: i18n.t('order_date') },
    { source: 'approve_date',component: 'date', label: i18n.t('approve_or_decline_date') },
    { source: 'deliver_date', component: 'date',label: i18n.t('deliver_date')},
    { source: 'status', label: i18n.t('status')  },
    {
        source: "items",count: "total_items" ,component: 'table', label: i18n.t('items'), direction: 'column', columns: [
            {source: 'name', label: i18n.t('name'), sortable: true},
            {source: 'description', label: i18n.t('description'), sortable: true},
            {source: 'unit', label: i18n.t('unit'), sortable: true},
            {source: 'order_quantity', label: i18n.t('order_quantity'), sortable: true},
        ],
    },
]


export const filters = [];

if (localStorage.permissions !== undefined && (localStorage.permissions.includes('list_all_warehouses') || localStorage.permissions.includes('list_my_warehouses'))) {
    filters.push({
            source: 'warehouse_id',
            label: i18n.t("warehouse_name"),
            component: 'select',
            type: 'remote',
            resource: 'warehouses',
            optionLabel: 'warehouse id',
            optionValue: 'id',
            container: 'filters',
            direction: 'column',
        },
    );
}

filters.push({
        label: i18n.t('status'),
        source: 'status',
        component: 'select',
        optionText: 'label',
        container: 'filters',
        direction: 'column',
        options: [
            {value: 'in progress', label: i18n.t('in progress')},
            {value: 'waiting', label: i18n.t('waiting')},
            {value: 'suspended', label: i18n.t('suspended')},
            {value: 'done', label: i18n.t('done')},
        ]
    },
    {
        source: 'order_date',
        direction: 'column',
        label: i18n.t('order_date'),
        component: 'date',
        type: 'range',
        container: 'filters',
    },
);

export const title = i18n.t("purchase_orders");
export const subtitle = "";