import { View} from "components/CRUD";
import TasksBoard from 'components/TasksBoard';
import TasksList from "../../../components/CRUD/TasksList";
import i18n from "i18n";
import ViewMaker from "components/FormBuilder/ViewMaker";
import {filters, ViewOne} from "./tasks.config"

export const Tasks = props => {
    return (
        <TasksList {...props} filters={filters} title={i18n.t("tasks")}>
            {({data, ...props}) =>
                <TasksBoard filters={filters} data={data} {...props}/>
            }
        </TasksList>
    );
}


export const TaskView = (props) => (
    <View title={i18n.t("view")}  url="/tasks" {...props}>
        {(props) => <ViewMaker resource={props.resource} fields={ViewOne} {...props}/>}
    </View>
  );
  

