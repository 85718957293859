import * as Yup from 'yup';
import i18n from "i18n";

export const createFields = [
  {
    source: 'name',
    label: i18n.t('name'),
    validation: Yup.string().required(i18n.t("required_field")).min(1, i18n.t('too_short')).max(50, i18n.t('too_long!')),
  },
  {
    source: 'description',
    label: i18n.t('description'),
    validation: Yup.string().required(i18n.t("required_field")),
  },
];

export const editFields = createFields;

export const columns = [
  {source: 'name', label: i18n.t('name'), sortable: true},
];

export const ViewOne = [
  {source: 'name', label: i18n.t('name')},
  {source: 'description', label: i18n.t('description')},
]
export const filters = [
];

export const [title, subtitle] = [
  i18n.t("stages"),
];