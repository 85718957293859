import {Create, Edit, List, View} from "components/CRUD";
import {DataTable} from "components/Tables";
import FormBuilder from "components/FormBuilder";
import ViewMaker from "components/FormBuilder/ViewMaker";
import {createFields, editFields, subtitle, filters, columns, ViewOne} from "./pipelines.config";
import i18n from "i18n"
export const PipelinesList = props => {
  return (
      <List {...props} filters={filters}>
        {({data, ...rest}) =>
            <DataTable resource={props.resource} data={data} columns={columns} {...rest}/>
        }
      </List>
  )
}

export const PipelinesCreate = props => {
  return (
      <Create {...props}>
          {(data) => <FormBuilder fields={createFields} {...props} {...data} />}
      </Create>
  );
}

export const PipelinesEdit = (props) => (
    <Edit title={i18n.t("edit")} {...props}>
        {(data) => <FormBuilder fields={editFields} {...props} {...data} />}
    </Edit>
);

export const PipelinesView = (props) => (
  <View title={i18n.t("view")} resource={props.resource} subtitle={subtitle} url="/pipelines" {...props}>
      {(props) => <ViewMaker fields={ViewOne} {...props}/>}
  </View>
);