import Cookies from 'js-cookie';
import axios from 'axios';

const instance = axios.create({
    baseURL: process.env.REACT_APP_API_URL
});

axios.interceptors.request.use(function (config) {
    // Do something before request is sent
    return config;
}, function (error) {
    // Do something with request error
    return Promise.reject(error);
});

axios.interceptors.response.use(function (response) {
    // Any status code that lie within the range of 2xx cause this function to trigger
    // Do something with response data
    return response;
}, function (error) {
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    // Do something with response error
    return Promise.reject(error);
});

if (Cookies.get('AUTH_TOKEN'))
    instance.defaults.headers.common['Authorization'] = Cookies.get('AUTH_TOKEN');


export default instance;