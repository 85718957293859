import  firebase from "firebase/app";
import "firebase/messaging";

const initializedFirebaseApp = firebase.initializeApp({
    apiKey: "AIzaSyANZ9mKwXHeCODqqonVRkKc5hHRRKYtNe8",
    authDomain: "auto-factory-2d788.firebaseapp.com",
    projectId: "auto-factory-2d788",
    storageBucket: "auto-factory-2d788.appspot.com",
    messagingSenderId: "769910719232",
    appId: "1:769910719232:web:113f6028ef7e9c683a69e5",
});

const messaging = initializedFirebaseApp.messaging();
export { messaging };