import React from "react";
import classes from 'classnames';

const InputContainerFormik = ({className, children}) => {
  return (
    <div className={classes(className)}>
      {children}
    </div>
  );
};

export default InputContainerFormik;