import React from "react";
import classes from 'classnames';
import {Col, Row} from "reactstrap";

const DefaultContainer = ({children, className, ...rest}) =>
    <Row className={classes("mb-3", className)}
         children={children} {...rest}
    />;

const getContainer = (container) => {
    if (React.isValidElement(container))
        return container;

    switch (container) {
        case 'row':
            return Row;

        case 'col':
            return Col;

        default:
            return DefaultContainer;
    }
};

const FieldContainerFormik = ({children, container, source, ...rest}) => {
    if (typeof container === 'function')
        return container({children});

    const Container = getContainer(container);
    return (
        <Container key={source} {...rest}>
            {children}
        </Container>
    );
};

export default FieldContainerFormik;