import { Create } from "components/CRUD";
import { createFields } from "./needinessCalculator.config";
import FormBuilder from "components/FormBuilder";

export const needinessCalculatorCreate = props => {
  return (
    <Create {...props}>
      {data => <FormBuilder fields={createFields} {...props} {...data} />}
    </Create>
  );
};
