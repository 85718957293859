import PropTypes from "prop-types";
import React, { useEffect, useRef, useState } from "react";
import "simplebar/dist/simplebar.min.css";
// //Import Scrollbar
import SimpleBar from "simplebar-react";

// MetisMenu
import MetisMenu from "metismenujs";
import { withRouter } from "react-router-dom";
import { Link } from "react-router-dom";
import i18n from "i18n";
//i18n
import { withTranslation } from "react-i18next";

const SidebarContent = props => {
  const ref = useRef();
  // Use ComponentDidMount and ComponentDidUpdate method symultaniously
  useEffect(() => {
    const pathName = props.location.pathname;

    const initMenu = () => {
      new MetisMenu("#side-menu");
      let matchingMenuItem = null;
      const ul = document.getElementById("side-menu");
      const items = ul.getElementsByTagName("a");
      for (let i = 0; i < items.length; ++i) {
        if (pathName === items[i].pathname) {
          matchingMenuItem = items[i];
          break;
        }
      }
      if (matchingMenuItem) {
        activateParentDropdown(matchingMenuItem);
      }
    };
    initMenu();
  }, [props.location.pathname]);

  useEffect(() => {
    ref.current.recalculate();
  });

  function scrollElement(item) {
    if (item) {
      const currentPosition = item.offsetTop;
      if (currentPosition > window.innerHeight) {
        ref.current.getScrollElement().scrollTop = currentPosition - 300;
      }
    }
  }

  const [active, setActive] = useState(null);

  function activateParentDropdown(item) {
    const parent = item.parentElement;
    const parent2El = parent.childNodes[1];
    if (parent2El && parent2El.id !== "side-menu") {
      parent2El.classList.add("mm-show");
    }

    if (parent) {
      if (active !== null) active.classList.remove("mm-active");
      setActive(parent);

      parent.classList.add("mm-active");
      const parent2 = parent.parentElement;

      if (parent2) {
        parent2.classList.add("mm-show"); // ul tag

        const parent3 = parent2.parentElement; // li tag

        if (parent3) {
          // parent3.classList.add("mm-active") // li
          // parent3.childNodes[0].classList.add("mm-active") //a
          const parent4 = parent3.parentElement; // ul
          if (parent4) {
            parent4.classList.add("mm-show"); // ul
            const parent5 = parent4.parentElement;
            if (parent5) {
              parent5.classList.add("mm-show"); // li
              // parent5.childNodes[0].classList.add("mm-active") // a tag
            }
          }
        }
      }
      scrollElement(item);
      return false;
    }
    scrollElement(item);
    return false;
  }

  return (
    <React.Fragment>
      <SimpleBar style={{ maxHeight: "100%" }} ref={ref}>
        <div id="sidebar-menu">
          <ul className="metismenu list-unstyled" id="side-menu">
            {localStorage?.permissions
              ?.split(",")
              .includes("view_dashboard") && (
              <li>
                <Link to="/dashboard" className="">
                  <i className="bx bx-home-circle" />
                  <span>{i18n.t("dashboards")}</span>
                </Link>
              </li>
            )}

            {(localStorage?.permissions
              ?.split(",")
              .includes("create_warehouses") ||
              localStorage?.permissions
                ?.split(",")
                .includes("list_all_warehouses") ||
              localStorage?.permissions
                ?.split(",")
                .includes("list_my_warehouses")) && (
              <>
                <li>
                  <Link to="/warehouses" className=" ">
                    <i className="bx bx-building-house" />
                    <span>{i18n.t("warehouses")}</span>
                  </Link>
                </li>
              </>
            )}

            {(localStorage?.permissions
              ?.split(",")
              .includes("create_categories") ||
              localStorage?.permissions
                ?.split(",")
                .includes("list_all_categories")) && (
              <>
                <li className="menu-title"></li>
                <li>
                  <Link to="/categories" className="">
                    <i className="bx bx-package" />
                    <span>{i18n.t("categories")}</span>
                  </Link>
                </li>
              </>
            )}

            {(localStorage?.permissions?.split(",").includes("create_items") ||
              localStorage?.permissions
                ?.split(",")
                .includes("list_all_items")) && (
              <li>
                <Link to="/items" className="">
                  <i className="bx bx-package" />
                  <span>{i18n.t("items")}</span>
                </Link>
              </li>
            )}

            {(localStorage?.permissions
              ?.split(",")
              .includes("create_products") ||
              localStorage?.permissions
                ?.split(",")
                .includes("list_all_products")) && (
              <li>
                <Link to="/products" className="">
                  <i className="bx bx-package" />
                  <span>{i18n.t("products")}</span>
                </Link>
              </li>
            )}

            {(localStorage?.permissions?.split(",").includes("create_users") ||
              localStorage?.permissions
                ?.split(",")
                .includes("list_all_users")) && (
              <>
                <li className="menu-title">{i18n.t("authorization")}</li>
                <li>
                  <Link to="/users" className="">
                    <i className="bx bxs-group" />
                    <span>{i18n.t("users")}</span>
                  </Link>
                </li>
              </>
            )}

            {localStorage != undefined &&
              JSON.parse(localStorage.user).role === "admin" && (
                <li>
                  <Link to="/spaces" className="">
                    <i className="bx bxs-group" />
                    <span>{i18n.t("spaces")}</span>
                  </Link>
                </li>
              )}

            {localStorage != undefined &&
              JSON.parse(localStorage.user).role === "admin" && (
                <li>
                  <Link to="/roles" className="">
                    <i className="bx bxs-group" />
                    <span>{i18n.t("roles")}</span>
                  </Link>
                </li>
              )}

            {(localStorage?.permissions?.split(",").includes("create_stages") ||
              localStorage?.permissions
                ?.split(",")
                .includes("list_all_stages")) && (
              <>
                <li className="menu-title">{i18n.t("pipelines")}</li>
                <li>
                  <Link to="/stages" className="">
                    <i className="bx bxs-layer" />
                    <span>{i18n.t("stages")}</span>
                  </Link>
                </li>
              </>
            )}

            {(localStorage?.permissions
              ?.split(",")
              .includes("create_pipelines") ||
              localStorage?.permissions
                ?.split(",")
                .includes("list_all_pipelines")) && (
              <li>
                <Link to="/pipelines" className="">
                  <i className="bx bxs-layer" />
                  <span>{i18n.t("pipelines")}</span>
                </Link>
              </li>
            )}

            {(localStorage?.permissions
              ?.split(",")
              .includes("create_purchase_orders") ||
              localStorage?.permissions
                ?.split(",")
                .includes("list_all_purchase_orders")) && (
              <>
                <li className="menu-title">{i18n.t("orders")}</li>
                <li>
                  <Link to="/purchase_orders" className="">
                    <i className="bx bxs-purchase-tag" />
                    <span>{i18n.t("purchase_orders")}</span>
                  </Link>
                </li>
              </>
            )}

            {(localStorage?.permissions?.split(",").includes("create_orders") ||
              localStorage?.permissions
                ?.split(",")
                .includes("list_all_orders")) && (
              <li>
                <Link to="/orders" className="">
                  <i className="bx bxs-purchase-tag" />
                  <span>{i18n.t("orders")}</span>
                </Link>
              </li>
            )}

            {(localStorage?.permissions
              ?.split(",")
              .includes("create_manufacture_orders") ||
              localStorage?.permissions
                ?.split(",")
                .includes("list_all_manufacture_orders")) && (
              <li>
                <Link to="/manufacture_orders" className="">
                  <i className="bx bxs-purchase-tag" />
                  <span>{i18n.t("manufacture_orders")}</span>
                </Link>
              </li>
            )}

            {(localStorage?.permissions?.split(",").includes("create_tasks") ||
              localStorage?.permissions
                ?.split(",")
                .includes("list_all_tasks") ||
              localStorage?.permissions
                ?.split(",")
                .includes("list_my_tasks")) && (
              <li>
                <Link to="/tasks" className="">
                  <i className="bx bxs-purchase-tag" />
                  <span>{i18n.t("tasks")}</span>
                </Link>
              </li>
            )}

            {localStorage?.permissions
              ?.split(",")
              .includes("approve_orders") && (
              <li>
                <Link to="/neediness/calculator/create">
                  <i className="bx bxs-purchase-tag" />
                  <span>{i18n.t("neediness_calculator")}</span>
                </Link>
              </li>
            )}
          </ul>
        </div>
      </SimpleBar>
    </React.Fragment>
  );
};

SidebarContent.propTypes = {
  location: PropTypes.object,
  t: PropTypes.any,
};

export default withRouter(withTranslation()(SidebarContent));
