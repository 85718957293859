import {
GET_ITEMS_DATA,
CLEAR_DATA
} from "./actionTypes"

const initialState = {
  items: []
}

const purchaseOrder = (state = initialState, action) => {
  switch (action.type) {
    case GET_ITEMS_DATA:
      state = {
        ...state,
        items: action.payload
      }
      break
    case CLEAR_DATA:
      state = {
        ...state,
        items: []
      }
    default:
      state = { ...state }
      break
  }
  return state
}

export default purchaseOrder
