import { useQuery, useUpdate } from "react-admin";
import Breadcrumb from "../../Common/Breadcrumb";
import { capitalCase } from "capital-case";
import { Card, CardBody, CardTitle, Col, Container, Row, Spinner, Alert } from "reactstrap";
import MetaTags from "react-meta-tags";
import toastr from "toastr"
import { useHistory } from "react-router";
import i18n from "i18next";
import {useEffect} from "react"

const Edit = (props) => {
    const { title, subtitle, resource, id, url: _url, transform, children } = props;

    let url = _url;
    if (url === undefined)
        url = '/' + resource

    const { loading: fetchLoading, error: fetchError, data: fetchData } = useQuery({
        type: 'getOne',
        resource: resource,
        payload: {
            id: id,
        },
    });
    useEffect(()=>{
        window.scrollTo(0, 0);
    },[])
    const [update, { loading: updateLoading, error: updateError, data: updateData }] = useUpdate(resource, id);
    const history = useHistory();

    const values = updateData ?? fetchData;

    const onSubmit = (values, actions) => {
        update(resource, id, transform(values), {}, {
            onFailure: (response) => {
                if (response.status === 422) {
                    actions.setErrors(response.body.errors);

                    let message = response.message;
                    if (response.body.errors['id'])
                        message = response.body.errors['id'][0];

                    toastr.error(i18n.t(message), null, { positionClass: 'toast-bottom-center' });
                }
                else if (response.status === 401) {
                    localStorage.removeItem('token');
                    localStorage.removeItem('permissions');
                    localStorage.removeItem('user');
                    localStorage.removeItem("fcmToken");
                    toastr.error(i18n.t("session_has_been_revoked"), null, { positionClass: 'toast-bottom-center' });
                    history.push("/login")

                } else {
                    toastr.error(i18n.t("something_went_wrong"), null, { positionClass: 'toast-bottom-center' });
                }
            },
            onSuccess: response => {
                toastr.success(i18n.t("successfully_updated"), null, { positionClass: 'toast-bottom-center' });
                history.push(url);
            }
        });
    }

    if (fetchLoading)
        return<div style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "100vh" }}>
        <Spinner color="primary" />
    </div>
    if (fetchError)
              return <Alert  style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "100vh" }} color="danger">{i18n.t(fetchError.message)}</Alert>;

    return (
        <div className="page-content">
            <MetaTags>
                <title> {i18n.t(`edit_${resource}`)} </title>
            </MetaTags>
            <Container fluid>
                <Row>
                    <Col>
                        <Breadcrumb title="edit" breadcrumbItem={resource} resource={resource} />
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <Card>
                            <CardBody>
                                <CardTitle className="h4">{i18n.t(title)}</CardTitle>
                                <p className="card-title-desc"> {i18n.t(subtitle)} </p>
                                {typeof children === 'function' ? children({
                                    onSubmit,
                                    loading: updateLoading,
                                    error: updateError,
                                    values: values
                                }) : children}
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </div>
    )
};

Edit.defaultProps = {
    transform: values => values,
}

export default Edit;