import {  Card, CardBody, Col, Container, Row } from "reactstrap";
import classes from "classnames";
import FormBuilder from "../../FormBuilder";


const Filters = props => {
    const InnerContainer = ({ children, className, ...rest }) => {
        return (
            <Row className={classes("mb-3 d-flex justify-content-start", className)} {...rest}>
                {children}
            </Row>
        );
    }
        const FieldContainer = ({ children, className, ...rest }) => {
        return (
            <Col md={3} sm={4} xs={12} className="">
                <Row className={classes('mb-3 d-flex justify-content-start', className)} {...rest}>
                    {children}
                </Row>
            </Col>
        );
    }

    const { onClick } = props;
    const ActionsContainer = ({ className, onClick, ...rest }) => {

    }

    const { filters, onSubmit } = props;
    return (filters.length > 0 ?
        <Container fluid>
            <Card>
                <CardBody>
                    <FormBuilder
                        fields={filters}
                        innerContainer={InnerContainer}
                        fieldContainer={FieldContainer}
                        actionsContainer={ActionsContainer}
                        type="filters"
                        onSubmit={onSubmit}
                        {...props}
                    />
                </CardBody>
            </Card>
        </Container>
        : <div></div>
    )
}

export default Filters;