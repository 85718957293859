import React from "react"
import {Col, Container, Row} from "reactstrap"

const Footer = () => {
  return (
    <React.Fragment>
      <footer className="footer">
        <Container fluid={true}>
          <Row>
            <Col md={6}>{new Date().getFullYear()} All rights reserved<a href="https://atmatah.tech" className="btn btn-link"> {localStorage.i18nextLng === 'ar' ? " Atmatah ©" : "© Atmatah"}</a>.</Col>
            <Col md={6}>
              <div className="text-sm-end d-none d-sm-block">
                Design & Develop by <a href="http://elite.sy/"> Elite </a>
              </div>
            </Col>
          </Row>
        </Container>
      </footer>
    </React.Fragment>
  )
};

export default Footer
