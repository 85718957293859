import api from "api_helper"
import axios from "axios";

const addDevice = async (fcmtoken) => {
    const AddDevice = new Request(`${process.env.REACT_APP_API_URL}/devices/update`, {
        method: 'PUT',
        body: JSON.stringify({
            uuid: "wqeqweca2",
            app_name: null,
            app_version: null,
            brand: null,
            model: null,
            os: null,
            os_version: null,
            fcm_token: fcmtoken,
            build_number: null
        }),
        headers: new Headers({
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${localStorage.getItem("token")}`,
            'Access-Control-Allow-Origin': 'no-cors',
        }),
    });


    const response2 = await fetch(AddDevice);
    if (response2.status < 200 || response2.status >= 300) {
        throw new Error(response2.statusText);
    }
    localStorage.setItem("fcmToken", fcmtoken);
    return await response2.json();
}


export default {
    login: async (values) => {
        const { email, password, fcmtoken } = values;

        const response = await axios.post(`${process.env.REACT_APP_API_URL}/login`, {
            email,
            password
        });

        if (response.status < 200 || response.status >= 300) {
            throw new Error(response.statusText);
        }
        const { token, user } = response.data;
        localStorage.setItem('token', token);
        localStorage.setItem('user', JSON.stringify({ id: user.id, email: user.email, image: user.image, name: user.name, role: user.role.name }));
        const permissions = [];
        user?.role?.permissions?.map(e => { permissions.push(e.name); });
        localStorage.setItem('permissions', permissions);
        return await addDevice(fcmtoken);
    },
    checkError: (error) => {
        const status = error.status;
        if (status === 401) {
            localStorage.removeItem('token');
            localStorage.removeItem('permissions');
            localStorage.removeItem('user');
            localStorage.removeItem("fcmToken");
            return Promise.reject();
        }
        else if (status === 403) {
            return Promise.reject({ redirectTo: '/profile' });
        }
        // other error code (404, 500, etc): no need to log out
        return Promise.resolve();
    },
    checkAuth: () => {
        if (localStorage.getItem('token')) {
            return Promise.resolve()
        } else {
            return Promise.reject({ redirectTo: '/profile' })
        };

    },
    logout: async () => {
        const response = await api.post(`/logout`, {
            fcm_token: localStorage.getItem("fcmToken") ?? null ,
        }, {
            headers: {
                'Authorization': `Bearer ${localStorage.token}`,
                // 'Access-Control-Allow-Origin': 'no-cors',
            }
        }).catch(err => {
            localStorage.removeItem('token');
            localStorage.removeItem('permissions');
            localStorage.removeItem('user');
            localStorage.removeItem("fcmToken");
            window.location.href= "/login"
            return Promise.reject({ redirectTo: '/login' });
        })
        localStorage.removeItem('token');
        localStorage.removeItem('permissions');
        localStorage.removeItem('user');
        localStorage.removeItem("fcmToken");
        return Promise.resolve();
    },
    getIdentity: () => {
    },
    getPermissions: () => {
        const permissions = localStorage.getItem('permissions')?.split(",");
        return permissions ? Promise.resolve(permissions) : Promise.reject();
    }
};