import { useQuery } from "react-admin";
import Breadcrumb from "../../Common/Breadcrumb";
import { capitalCase } from "capital-case";
import {
  Card,
  CardBody,
  CardTitle,
  Col,
  Container,
  Row,
  Spinner,
  Alert,
} from "reactstrap";
import MetaTags from "react-meta-tags";
import i18n from "i18n";
import { useEffect } from "react";

const View = props => {
  const { title, subtitle, resource, id, url, transform, children, renderer } =
    props;

  const {
    loading: fetchLoading,
    error: fetchError,
    data: fetchData,
    response,
  } = useQuery({
    type: "getOne",
    resource: resource,
    payload: {
      id: id,
    },
  });

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const values = fetchData;

  if (fetchLoading)
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
        }}
      >
        <Spinner color="primary" />
      </div>
    );

  if (fetchError) return <Alert color="danger"> {i18n.t(fetchError)}</Alert>;

  return (
    <div className="page-content">
      <MetaTags>
        <title> {i18n.t(resource)} </title>
      </MetaTags>
      <Container fluid>
        <Row>
          <Col>
            <Breadcrumb
              title="view"
              breadcrumbItem={resource}
              resource={resource}
            />
          </Col>
        </Row>
        <Row>
          <Col>
            <Card>
              <CardBody>
                <CardTitle className="h4">{i18n.t(title)}</CardTitle>
                <p className="card-title-desc"> {i18n.t(subtitle)} </p>
                {typeof children === "function"
                  ? children({
                      loading: fetchLoading,
                      error: fetchError,
                      values: values,
                      title,
                      resource,
                    })
                  : children}
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

View.defaultProps = {
  transform: values => values,
};

export default View;
