import { capitalCase } from "capital-case";
import classes from 'classnames';
import { Objects ,Description, Image, MapView, ArrayObject , ProgressBar, DataTable, Date} from "./index";
import { Label, } from "reactstrap";
import i18n from "i18n"

export const getView = (component, type) => {
    if (typeof component === 'function')
        return component;
    switch (component) {
        case 'desc':
            return Description;
        case 'table':
            return DataTable;
        case 'object':
            return Objects;
        case 'image':
            return Image;
        case 'map':
            return MapView;
        case 'ArrayObject':
            return ArrayObject;
        case 'progress':
            return ProgressBar;
        case 'date':
            return Date
        default:
            return Description;
    }
}

const ViewMake = (props) => {
    const { component, type, source,title ,label: _label, value, direction, className, disabled,total, ...rest } = props;
    const View = getView(component, type);
    const label = _label ?? capitalCase(source);
    return (
        <div className={className + " row "}>
            <Label source={source} className={classes({ "col-md-3": direction === 'row'})}> {i18n.t(label)} {" "}: </Label>
            <div className={classes({ "col-md-9": direction === 'row' })}>
                <View title={title} value={value} total={total} source={source} type={type}  {...rest} />
            </div>
        </div>
    );
}

ViewMake.defaultProps = {
    direction: 'row',
};

export default ViewMake;