import { isValidElement, useState } from "react";
import * as Yup from "yup";

import "react-toggle/style.css";

import {
  Alert,
  Button,
  Col,
  Container,
  Row,
  Spinner,
  Modal,
  Form,
  ModalBody,
  ModalHeader,
  ModalFooter,
} from "reactstrap";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import { Search } from "react-bootstrap-table2-toolkit";
import "./datatables.scss";
import PropTypes from "prop-types";
import { capitalCase } from "change-case";
import { useHistory } from "react-router";
import { Link } from "react-router-dom";
import i18n from "i18n";
import api from "api_helper";
import toastr from "toastr";
import { connect } from "react-redux";
import { getItemsData } from "../../store/purchaseOrder/actions";
import { ArrayFormik } from "components/FormBuilder/components";
import CalculatorModal from "./CalculatorModal";

const getColumns = ({ columns: _columns, children, onSort }) => {
  let columns = [];
  if (!_columns) {
    if (children) {
      columns = children.filter(isValidElement).map(({ props }, index) => ({
        ...props,
        dataField: props?.source,
        text:
          props?.text ??
          props?.label ??
          capitalCase(props?.source ?? index.toString()),
        sort: props?.sortable ?? false,
        key: props?.source ?? index,
        formatter: props?.renderer,
        onSort: onSort,
      }));
    } else if (!!data && data.length > 0) {
      columns = Object.keys(data[0]).map((key, index) => ({
        dataField: key,
        text: capitalCase(key.toString()),
        key: index,
        sort: false,
      }));
    }
  } else {
    columns = _columns.map((props, index) => ({
      ...props,
      dataField: props?.source,
      text:
        props?.text ??
        props?.label ??
        capitalCase(props?.source ?? index.toString()),
      sort: props?.sortable ?? false,
      key: props?.source ?? index,
      formatter: props?.renderer,
      onSort: onSort,
    }));
  }

  return columns;
};

const getActions = ({
  resource,
  editable: __editable,
  editUrl: _editUrl,
  creatable: _creatable,
  createUrl: _createUrl,
  deletable: __deletable,
}) => {
  const _editable = __editable ?? true;
  const editUrl = _editUrl ?? `${resource}/:id/edit`;
  const editable = !!_editable && !!editUrl;

  const _deletable = __deletable ?? true;
  const deletable = !!_deletable;

  const createUrl = _createUrl ?? `${resource}/create`;
  const creatable = !!_creatable && !!createUrl;

  return { creatable, editable, deletable, createUrl, editUrl };
};

const DatatableTable = props => {
  const {
    getItemsData,
    data,
    total,
    loading,
    error,
    page,
    pageSize,
    searchQuery,
    sort,
    title,
    resource,
    permissions,
    onDelete,
    onSearch,
    onPageChange,
    refetch,
    onPageSizeChange,
  } = props;
  const history = useHistory();
  const columns = getColumns(props);
  const { editable, deletable, createUrl, editUrl } = getActions(props);

  const [showButton, setShowButton] = useState(true);
  const [modal, setModal] = useState(false);
  const [modalData, setModalData] = useState([]);
  const [navigationData, setNavigationData] = useState(null);
  const [modalLoading, setModalLoading] = useState(false);
  const [warehouse, setWarehouse] = useState(null);
  const [warehouseOptions, setWarehouseOptions] = useState(null);
  const [add_remove, setAddRemove] = useState(null);

  const [WarehouseModal, setWarehouseModal] = useState(false);
  const [WarehouseModalData, setWarehouseModalData] = useState([]);

  const handleAddItemClick = () => history.push(createUrl);
  const handleEditClick = row => {
    if (row.editable) {
      const to = "/" + editUrl.replace(":id", row.id);
      history.push(to);
    } else {
      toastr.error(i18n.t("element_is_not_editable"), null, {
        positionClass: "toast-bottom-center",
      });
    }
  };

  const handleViewClick = () => {};

  const handleManaulClickAdd = row => {
    setWarehouse(row);
    api
      .get(`/warehouses/${row.id}/items?take=10&skip=-1`)
      .then(res => {
        setAddRemove(true);
        let array = [];
        res.data.map(e => {
          array.push({
            value: e.id,
            label: e.name,
            process: true,
          });
        });
        setWarehouseOptions(array);
        setWarehouseModal(true);
      })
      .catch(error => {
        toastr.error(i18n.t(error.response.data.message), null, {
          positionClass: "toast-bottom-center",
        });
      });
  };

  const handleManaulClickDelete = row => {
    setWarehouse(row);
    api
      .get(`/warehouses/${row.id}/items?take=10&skip=-1`)
      .then(res => {
        setAddRemove(false);

        let array = [];
        res.data.map(e => {
          array.push({
            value: e.id,
            label: e.name,
            process: false,
          });
        });
        setWarehouseOptions(array);
        setWarehouseModal(true);
      })
      .catch(error => {
        toastr.error(i18n.t(error.response.data.message), null, {
          positionClass: "toast-bottom-center",
        });
      });
  };

  const handleDeleteClick = (resource, id) => {};
  const Url = process.env.REACT_APP_API_URL;
  const viewUrl = `/${resource}/:id/show`;

  const handleCustomApprove = async (e, row, status) => {
    const res = api
      .post(`/${resource}/approve`, {
        id: row.id,
        approve: status == "approve" ? true : false,
      })
      .then(response => {
        refetch();
        if (status === "decline") {
          toastr.info(i18n.t("successfully_declined"), null, {
            positionClass: "toast-bottom-center",
          });
        } else {
          toastr.info(i18n.t("successfully_approved"), null, {
            positionClass: "toast-bottom-center",
          });
        }
      })
      .catch(error => {
        toastr.error(
          "Can't " +
            i18n.t(status) +
            " this " +
            resource.substr(0, resource.length - 1).replace("_", " "),
          null,
          { positionClass: "toast-bottom-center" }
        );
      });
  };

  const renderEdit = row => {
    return (
      <div style={{ cursor: "pointer" }} className="text-success">
        <i
          className="mdi mdi-pencil font-size-18"
          onClick={() => handleEditClick(row)}
        />
      </div>
    );
  };
  const renderCheck = row => {
    if (resource === "purchase_orders" && row?.status === "waiting") {
      return (
        <div className="d-flex gap-2">
          <button
            onClick={e => handleCustomApprove(e, row, "approve")}
            className={`btn btn-primary ${
              resource === "orders" && !row.can_approve && "disabled"
            }`}
          >
            {i18n.t("approve")}
          </button>

          <button
            onClick={e => handleCustomApprove(e, row, "decline")}
            className="btn btn-danger"
          >
            {i18n.t("decline")}
          </button>
        </div>
      );
    } else if (resource === "orders" && row?.approve_status === "waiting") {
      return (
        <div className="d-flex gap-2">
          {row.can_approve ? (
            <button
              onClick={e => handleCustomApprove(e, row, "approve")}
              className={`btn btn-success ${
                resource === "orders" && !row.can_approve && "disabled"
              }`}
            >
              {i18n.t("approve")}
            </button>
          ) : (
            <button
              onClick={e => hanldeShowDetails(row)}
              className={`btn btn-primary`}
            >
              {i18n.t("details")}
            </button>
          )}

          <button
            onClick={e => handleCustomApprove(e, row, "decline")}
            className="btn btn-danger"
          >
            {i18n.t("decline")}
          </button>
        </div>
      );
    } else if (
      resource === "purchase_orders" &&
      row?.status === "in progress"
    ) {
      return (
        <div className=" btn btn-success">
          <div onClick={() => handleEditClick(row)}>
            {i18n.t("complete_data")}
          </div>
        </div>
      );
    }
    return null;
  };

  const renderDelete = row => {
    return (
      <Link className="text-danger" to="#">
        <i
          className="mdi mdi-delete font-size-18"
          onClick={() => onDelete(row, row.id)}
        />
      </Link>
    );
  };

  const renderView = row => {
    return (
      <Link className="text-primary" to={viewUrl.replace(":id", row.id)}>
        <i
          className={`mdi mdi-arrow-${
            localStorage?.I18N_LANGUAGE == "ar" ? "left" : "right"
          }-bold font-size-24`}
          onClick={() => handleViewClick(row.id)}
        />
      </Link>
    );
  };

  const renderManual = row => {
    return (
      <>
        <Button color="primary" onClick={() => handleManaulClickAdd(row)}>
          {i18n.t("add")}
        </Button>
        <Button color="success" onClick={() => handleManaulClickDelete(row)}>
          {i18n.t("remove")}
        </Button>
      </>
    );
  };
  const renderAddItem = row => {
    return (
      <Link to={`${resource}/${row.id}/edit`} className="btn btn-info">
        {i18n.t("add_items")}
      </Link>
    );
  };

  const handleEditManual = () => {
    let data = WarehouseModalData || [];
    data?.map(e => {
      e.process = add_remove;
      e.quantity = Number(e.quantity);
    });
    const res = api
      .post(`/warehouses/manual`, {
        warehouse_id: warehouse.id,
        items: WarehouseModalData,
      })
      .then(res => {
        toastr.success(i18n.t("successfully_updated"), null, {
          positionClass: "toast-bottom-center",
        });
        setWarehouseModal(false);
        setWarehouseModalData([]);
      })
      .catch(error => {
        toastr.error(i18n.t(error.response.data.message), null, {
          positionClass: "toast-bottom-center",
        });
      });
  };

  const hanldeShowDetails = row => {
    setModalLoading(true);
    const res = api
      .post(`/${resource}/calculator`, {
        order_id: row.id,
      })
      .then(response => {
        setNavigationData(response.data);
        let data = [];
        let r = 0;
        response.data.items.map(e => {
          r += e.required_quantity;
          data.push({
            name: e.item.name,
            unit: e.item.unit,
            order_quantity: e.order_quantity,
            available_quantity: e.available_quantity,
            required_quantity: e.required_quantity,
          });
        });
        setModalData(data);
        setShowButton(r !== 0);
      })
      .catch(error => {
        toastr.error(i18n.t("server_error"), null, {
          positionClass: "toast-bottom-center",
        });
      })
      .finally(() => {
        setModalLoading(false);
      });

    setModal(true);
  };

  if (editable || deletable) {
    if (resource != "manufacture_orders") {
      columns.push({
        dataField: "id",
        isDummyField: true,
        editable: false,
        text: i18n.t("action"),
        formatter: (cellContent, row) => (
          <div className="d-flex gap-3">
            {renderEdit(row)}
            {renderDelete(row)}
          </div>
        ),
      });
    }
  }

  columns.push({
    dataField: "view",
    isDummyField: true,
    editable: false,
    text: i18n.t("view"),
    formatter: (cellContent, row) => (
      <div className="d-flex gap-3">{renderView(row)}</div>
    ),
  });

  if (resource === "warehouses") {
    columns.push({
      dataField: "Add/Edit manually",
      isDummyField: true,
      editable: false,
      text: i18n.t("add_edit_manually"),
      formatter: (cellContent, row) => (
        <div className="d-flex gap-3">{renderManual(row)}</div>
      ),
    });
    columns.push({
      dataField: "settings",
      isDummyField: true,
      editable: false,
      text: i18n.t("settings"),
      formatter: (cellContent, row) => (
        <div className="d-flex gap-3">{renderAddItem(row)}</div>
      ),
    });
  }

  if (
    (resource === "purchase_orders" &&
      localStorage?.permissions
        ?.split(",")
        ?.includes("approve_purchase_orders")) ||
    (resource === "orders" &&
      localStorage?.permissions?.split(",")?.includes("approve_orders"))
  ) {
    columns.push({
      dataField: "status",
      isDummyField: true,
      editable: false,
      text: i18n.t("approval_status"),
      formatter: (cellContent, row) => (
        <div className="d-flex gap-2">{renderCheck(row)}</div>
      ),
    });
  }

  const manual_edit = [
    {
      source: "id",
      label: i18n.t("items"),
      component: "select",
      options: warehouseOptions,
      optionLabel: "name",
      distinct: true,
      optionValue: "id",
      initialValue: ({ item: { id } }) => id,
    },
    {
      source: "quantity",
      showErrorWithoutTouch: true,
      label: i18n.t("quantity"),
      validation: Yup.number().required(i18n.t("required_field")),
    },
  ];
  const paginationConfig = {
    keyField: "datatable-pagination",
    totalSize: total,
    page: page,
    sizePerPage: pageSize,
    onPageChange: page => onPageChange(page),
    onSizePerPageChange: pageSize => onPageSizeChange(pageSize),
  };
  const createPurchaseOrder = (e, row) => {
    let items = [];
    row.items.length > 0 &&
      row.items.map(e => {
        items.push({ id: e.item.id, order_quantity: e.required_quantity });
      });
    getItemsData(items);
    history.push("/purchase_orders/create");
  };
  const { SearchBar } = Search;

  const handleChange = e => {
    setWarehouseModalData(e);
  };
  const handleClose = () => {
    setWarehouseModal(false);
    setWarehouseModalData([]);
  };
  return (
    <Container fluid>
      <Row className="mb-2 d-flex justify-content-between">
        <Col md={4}>
          <div className="search-box me-2 mb-2 d-inline-block">
            <div className="position-relative">
              <SearchBar
                searchText={searchQuery}
                onSearch={onSearch}
                placeholder={i18n.t("search")}
              />
              <i className="bx bx-search-alt search-icon" />
            </div>
          </div>
        </Col>
        {(permissions?.includes(`create_${resource}`) ||
          (resource === "roles" &&
            localStorage != undefined &&
            JSON.parse(localStorage.user).role === "admin") ||
          (resource === "spaces" &&
            localStorage != undefined &&
            JSON.parse(localStorage.user).role === "admin") ||
          JSON.parse(localStorage.user).role === "manger") && (
          <Col sm={4}>
            <div className="text-sm-end">
              <Button
                color="primary"
                className="font-16 btn-block btn btn-primary"
                onClick={handleAddItemClick}
              >
                <i className="mdi mdi-plus-circle-outline me-1" />
                {i18n.t("create") + " " + i18n.t("_" + resource)}
              </Button>
            </div>
          </Col>
        )}
      </Row>
      <Row>
        <Col xl={12}>
          {error && <Alert color="danger">{i18n.t(error.message)}</Alert>}
          {loading ? (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Spinner color="primary" />
            </div>
          ) : (
            <BootstrapTable
              key="name"
              keyField="name"
              remote
              responsive
              data={data}
              columns={columns}
              loading={loading}
              bordered={false}
              striped={false}
              headerWrapperClasses={"thead-light"}
              pagination={paginationFactory(paginationConfig)}
              sizePerPageList={props.sizePerPageList}
              onTableChange={console.log}
              classes={"table table-nowrap"}
              bodyStyle={{ wordBreak: "break-all" }}
              rowStyle={{ whiteSpace: "pre-wrap", cursor: "default" }}
            />
          )}
        </Col>
      </Row>
      <CalculatorModal
        modal={modal}
        setModal={setModal}
        modalLoading={modalLoading}
        modalData={modalData}
        createPurchaseOrder={createPurchaseOrder}
        navigationData={navigationData}
        show={showButton}
      />

      <Modal
        draggable={true}
        size="xl"
        style={{ top: "10%" }}
        aria-multiselectable
        isOpen={WarehouseModal}
        toggle={handleClose}
      >
        <ModalHeader toggle={handleClose}>
          {!!add_remove ? i18n.t("manual_add") : i18n.t("manual_remove")}
        </ModalHeader>
        <ModalBody>
          <div
            style={{
              paddingRight: "20px",
              margin: "0em 4em 0em 4em",
              maxHeight: "400px",
              scrollBehavior: "smooth",
              overflowY: "auto",
              overflowX: "hidden",
            }}
          >
            <Form className="repeater" encType="multipart/form-data">
              <ArrayFormik
                onChange={handleChange}
                fields={manual_edit}
                value={WarehouseModalData}
                resource="warehouses"
                soruce="items"
                id={warehouse?.id}
              />
            </Form>
          </div>
        </ModalBody>
        <ModalFooter className="d-flex justify-content-between">
          <Button color="primary" onClick={handleEditManual}>
            {i18n.t("edit")}
          </Button>
        </ModalFooter>
      </Modal>
    </Container>
  );
};

DatatableTable.defaultProps = {
  defaultSorted: [
    {
      dataField: "id",
      order: "asc",
    },
  ],
  sizePerPageList: [
    { text: "5", value: 5 },
    { text: "10", value: 10 },
    { text: "15", value: 15 },
    { text: "20", value: 20 },
    { text: "25", value: 25 },
  ],
  keyField: "id",
  data: [],
};

DatatableTable.propTypes = {
  data: PropTypes.array,
  columns: PropTypes.array,
};
const mapStateToProps = state => ({});

export default connect(mapStateToProps, { getItemsData })(DatatableTable);
