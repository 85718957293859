import React, { useCallback, useEffect, useState } from "react";
import MetaTags from "react-meta-tags";
import { Container, Spinner, Alert } from "reactstrap";
import { map } from "lodash";
import toastr from "toastr";
//Import Breadcrumb
import "assets/scss/tasks.scss";
//redux
import UncontrolledBoard from "./UncontrolledBoard";
import api from "api_helper";
import i18n from "i18n";
import { useHistory } from "react-router";
//Import Task Cards

const TasksKanban = props => {
  const tasks = [];
  const { finalData: _data, setFinalData: setData } = props;
  const { changed } = props;
  const data = map(tasks, task => ({ ...task, cards: task.tasks }));
  data.length = Math.min(data.length, 1);
  const [columns, setColumns] = useState([]);
  const history = useHistory();

  const { error, loadingCount } = props;
  const { allWithData } = props;


  useEffect(() => {
    if (loadingCount !== 4)
      return (
        <div className="d-flex justify-content-center align-items-center ml-3">
          <Spinner color="primary" />
        </div>
      );
    if (error) {
      if (error.request.status === 401) {
        localStorage.removeItem("token");
        localStorage.removeItem("permissions");
        localStorage.removeItem("user");
        localStorage.removeItem("fcmToken");
        toastr.error(i18n.t("session_has_been_revoked"), null, {
          positionClass: "toast-bottom-center",
        });
        history.push("/login");
      }
      return <Alert color="danger"> {i18n.t(error.message)}</Alert>;
    }

    setColumns(
      _data.map((tasks, i) => {
        const { data: _Data, status, loading } = tasks;
        return {
          id: i,
          title: capitalize(status),
          status: status,
          cards: !!_Data
            ? _Data.map(task => {
                return {
                  id: task.id,
                  description: task.description,
                  title: task.name,
                  status: task.status,
                  manager: task.manager_name,
                };
              })
            : [
                {
                  loading: true,
                  status: status,
                },
              ],
        };
      })
    );
  }, [_data]);
  if (loadingCount === 0 || !allWithData)
    return (
      <div className="d-flex justify-content-center align-items-center ml-3">
        <Spinner color="primary" />
      </div>
    );
  if (error) {
    if (error.request.status === 401) {
      localStorage.removeItem("token");
      localStorage.removeItem("permissions");
      localStorage.removeItem("user");
      localStorage.removeItem("fcmToken");
      toastr.error(i18n.t("session_has_been_revoked"), null, {
        positionClass: "toast-bottom-center",
      });
      history.push("/login");
    }
    return <Alert color="danger">{i18n.t(error.message)}</Alert>;
  }

  const capitalize = str => {
    return str.charAt(0).toUpperCase() + str.slice(1);
  };

  const board = {
    columns: columns,
  };

  const { allStatus } = props;

  const onChange = (card, fromColumnId, toColumnId) => {
    const newColumns = columns;
    newColumns[toColumnId].cards = [...newColumns[toColumnId].cards, card];
    newColumns[fromColumnId].cards = newColumns[fromColumnId].cards.reduce(
      (res, cur) => {
        if (cur.id === card.id) return { data: res.data };
        return {
          data: [...res.data, cur],
        };
      },
      { data: [] }
    ).data;
    setColumns([...newColumns]);

    const newData = _data;
    const item = newData[fromColumnId].data.find(e => {
      return e.id === card.id;
    });
  };

  const onDrag = (card, source, destination) => {
    const { fromColumnId } = source;
    const { toColumnId } = destination;
    const { id } = card;

    if (toColumnId !== fromColumnId) {
      card.loading = true;
      card.status = allStatus[toColumnId].state;

      onChange(card, fromColumnId, toColumnId);

      api
        .patch("/tasks/" + id, {
          status: allStatus[toColumnId].state,
        })
        .then(response => {
          card.status = allStatus[toColumnId].state;
          _data[toColumnId].refetch();
          _data[fromColumnId].refetch();

          card.loading = false;
        })
        .catch(response => {
          card.loading = false;

          toastr.error(i18n.t("something_went_wrong"), null, {
            positionClass: "toast-bottom-center",
          });
        });
    }
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>{i18n.t("tasks")}</title>
        </MetaTags>
        <Container fluid>
          <UncontrolledBoard board={board} content={tasks} onDrag={onDrag} />
        </Container>
      </div>
    </React.Fragment>
  );
};

export default TasksKanban;
