import i18n from "i18next"
import detector from "i18next-browser-languagedetector"
import {initReactI18next} from "react-i18next"
import backend from "i18next-xhr-backend";

import translationAr from "./locales/ar/translation.json"
import translationEN from "./locales/en/translation.json"

// the translations
const resources = {
    ar: {
        translation: translationAr,
    },
    en: {
        translation: translationEN,
    },
}

const language = localStorage.getItem("I18N_LANGUAGE")
if (!language) {
    localStorage.setItem("I18N_LANGUAGE", "ar")
}

i18n.use(detector)
    .use(initReactI18next) // passes i18n down to react-i18next
    .use(backend)
    .init({
        resources,
        lng: localStorage.getItem("I18N_LANGUAGE") || "ar",
        fallbackLng: "ar", // use en if detected lng is not available

        keySeparator: false, // we do not use keys in form messages.welcome

        interpolation: {
            escapeValue: false, // react already safes from xss
        },


    }).then(() => {
    console.log('I18N_LANGUAGE Loaded');
})

export default i18n
