import React from "react";
import ReactApexChart from "react-apexcharts";
import i18n from "i18n";

const BarChart = ({ data, dataTitle, loading }) => {
  const labels = [];
  const barOne = [];
  const barTwo = [];
  let series = []

  if (data && dataTitle == "suspended_orders") {

      data?.map(e => {
        labels.push(
          new Date(e.from).toLocaleDateString() +
            " - " +
            new Date(e.to).toLocaleDateString()
        );
        barOne.push(i18n.t(e.total_count));
        barTwo.push(i18n.t(e.suspended_count))
      });
      series = [
        {
          name: i18n.t("total_count"),
          data: barOne,
        },
        {
          name: i18n.t("suspended_count"),
          data: barTwo,
        },
      ] 
  } else if(data && dataTitle === 'blocked_orders') {
 
      data?.map(e => {
        labels.push(
          new Date(e.from).toLocaleDateString() +
            " - " +
            new Date(e.to).toLocaleDateString()
        );
        barOne.push(i18n.t(e.blocked_count));
      });

      series = [ {
        name: i18n.t("blocked_count"),
        data: barOne,
      },]
  }
  const state = {
    chart: {
      type: "bar",
      stacked: true,
    },
    plotOptions: {
      bar: {
        columnWidth: "30%",
        horizontal: false,
      },
    },
    series: series,
    xaxis: {
      categories: labels,
      labels: {
        rotate: localStorage.I18N_LANGUAGE == 'ar' ? 40 : -40,
        formatter: function (val, opt) {
          return labels.length > 0 && val && val.split(" - ")[0]
        },
        enabled: true,
      },
    },
    fill: {
      opacity: 1,
    },
     
    title: {
      text: dataTitle == 'suspended_orders' ? i18n.t("suspended_orders") : i18n.t("blocked_orders"),
      align: "center",
      offsetY: 0,
      offsetX: 0,
    },
  };
  return (
    <React.Fragment>
     <ReactApexChart
        options={state}
        series={state.series}
        type="bar"
        height={345}
      />
    </React.Fragment>
  );
};

export default BarChart;
