import axios from "axios";
import toastr from "toastr";
import i18n from "i18n"


const api = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  headers: {
    "Authorization": `Bearer ${localStorage.getItem("token")}`,
  },
});

api.interceptors.response.use(
  response => response,
  error => {
    if(error.message === "Network Error"){
      toastr.error(i18n.t("network_error"), null, { positionClass: 'toast-bottom-center' });
      return Promise.reject(error)
    }
    else if (error.response.status === 401) {
        localStorage.removeItem("token");
        localStorage.removeItem("permissions");
        localStorage.removeItem("user");
        localStorage.removeItem("fcmToken");
        toastr.error(i18n.t("session_has_been_revoked"), null, { positionClass: 'toast-bottom-center' });
        window.location.href = "/login"
        return Promise.reject(error);
    }else if(error.response.status === 500) {
        toastr.error(i18n.t("server_error"), null, { positionClass: 'toast-bottom-center' });
    }else {
      return Promise.reject(error)
    }
  }
);



export default api;
