import * as Yup from "yup";
import i18n from "i18n";

export const createFields = [
  {
    source: 'name',
    label: i18n.t('name'),
        
    validation: Yup.string().required(i18n.t("required_field")).min(1, i18n.t('too_short')).max(50, i18n.t('too_long!')),
  },
  {
    source: 'description',
    label: i18n.t('description'),
  },
  {
    source: 'permissions',
    label: i18n.t('permissions'),
    validation: Yup.array().required(i18n.t("required_field")),
    component: 'list',
    Optgroup: ['create', 'list', 'edit', 'delete'],
    fields: [
      {
        source: 'name',
        label: i18n.t('name'),
        validation: Yup.string().required(i18n.t("required_field")),
      }, {
        source: 'type',
        label: i18n.t('type'),
        validation: Yup.string().required(i18n.t("required_field")),
      },
    ]
  },
];

export const columns = [
  { source: 'name', label: i18n.t('name'), sortable: true },
];

export const ViewOne = [
  { source: 'name', label: i18n.t('name') },
  { source: 'description', label: i18n.t('description') },
  { source: 'permissions', component: 'ArrayObject', target: 'name', label: i18n.t('permissions') },
]


export const filters = [

];

export const editFields = createFields;

export const title = i18n.t("roles");
export const subtitle = ''
