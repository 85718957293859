import React from "react";
import {Button, Col, Row} from "reactstrap";
import i18n from "i18n";


const FiltersActionContainer = props => {
    const {handleSubmit, onCancel} = props;
  
    return (
        <React.Fragment>
            <Row className="d-flex justify-content-end">
                <Col className="d-flex justify-content-end">
                    <Button color="primary" style={{marginRight:"5px", marginLeft:"5px", padding:"10px 1.3em" }} onClick={handleSubmit}>{i18n.t('filter')}</Button>
                    <Button color="danger" style={{padding:"10px 1.3em" }} onClick={onCancel}>{i18n.t('cancel')}</Button>
                </Col>
            </Row>
        </React.Fragment>
    );
}

export default FiltersActionContainer;