import {Create, Edit, List, View} from "components/CRUD";
import {DataTable} from "components/Tables";
import FormBuilder from "components/FormBuilder";
import {createFields, editFields, subtitle, filters, columns, ViewOne} from "./warehouses.config";
import ViewMaker from "components/FormBuilder/ViewMaker";
import i18n from "i18n"

export const WarehousesList = props => {
    return (
        <List {...props} filters={filters}>
            {({data, ...rest}) =>
                <DataTable resource={props.resource} data={data} columns={columns} {...rest}/>
            }
        </List>
    )
}

export const WarehousesCreate = props => {
    return (
        <Create {...props}>
            {(data) => <FormBuilder fields={createFields} {...props} {...data} />}
        </Create>
    );
}

export const WarehousesEdit = (props) => (
    <Edit title={i18n.t("edit")} {...props}>
        {(data) => <FormBuilder fields={editFields} {...props} {...data} />}
    </Edit>
);


export const WarehousesView = (props) => (
    <View title={i18n.t("view")} subtitle={subtitle}  {...props}>
        {(data) => <ViewMaker resource={props.resource} fields={ViewOne} {...props} {...data} />}
    </View>
);