import React from "react"

import Board from "@lourenci/react-kanban"
import CardTaskBox from "./card-task-box"
import RenderCardTitle from "./render-card-title"
import i18n from "i18n";


const UncontrolledBoard = (props) => {
  const {board, onDrag} = props
  return (
    <React.Fragment>
          <Board
            allowRemoveColumn={false}
            allowRemoveCard={false}
            allowAddColumn={false}
            onCardDragEnd={onDrag}

            renderColumnHeader={({ title }) => (
              <RenderCardTitle title={i18n.t(title)} />
            )}
            renderCard={(data, { dragging }) => {
             return <CardTaskBox data={data} dragging={dragging}>
                {data}
              </CardTaskBox>
            }}
          >{board}</Board>
    </React.Fragment>
  )
}

export default UncontrolledBoard
