import classes from "classnames";
import React from "react";
import "flatpickr/dist/themes/material_blue.css"
import Flatpickr from "react-flatpickr"
import moment from "moment"

const timeStampFormik = props => {
    const { source, value, touched, error, type, placeholder, disable, onChange, defaultValue, data } = props;
    if(!value){
        const date = moment(new Date()).format("YYYY-MM-DD hh:mm:ss")
        onChange(date)
    }

    return (
        <Flatpickr
            data-enable-time
            placeholder={placeholder}
            id={source}
            name={source}
            value={value}  
            type={type}
            disable={disable}
            className={classes("form-control", { "is-touched": !!touched, "is-invalid": !!error })}
            onChange={(date, dateStr) => typeof onChange === 'function' && onChange(dateStr) && console.log(dateStr)}
            options={{
                minDate: source === 'deliver_date' && data?.order_date  ?  data.order_date : '' ,
                altInput: true,
                defaultDate: 'today',
                dateFormat: "Y-m-d H:i",
            }}
        />
    );
}

export default timeStampFormik;