import {TextField,} from "react-admin";
import React from "react";


import {Create, Edit, List, View} from "components/CRUD";
import {DataTable} from "components/Tables";
import FormBuilder from "components/FormBuilder";
import {createFields, editFields, filters, subtitle, columns, ViewOne} from "./manufacture-orders.config";
import i18n from "i18n";
import ViewMaker from "components/FormBuilder/ViewMaker";

export const ManufactureOrdersList = props => {
    return (
        <List {...props} filters={filters}>
            {({data, ...rest}) =>
                <DataTable resource={props.resource} data={data} columns={columns} {...rest}/>
            }
        </List>
    )
}

export const ManufactureOrdersCreate = props => {
    return (
        <Create {...props}>
            {(data) => <FormBuilder fields={createFields} {...props} {...data} />}
        </Create>
    );
}

export const ManufactureOrdersEdit = (props) => (
    <Edit title={i18n.t("edit")} {...props}>
        {(data) => <FormBuilder fields={editFields} {...props} {...data} />}
    </Edit>
);

export const ManufactureOrdersView = (props) => (
    <View title={i18n.t("view")} resource={props.resource} subtitle={subtitle} url="/manufacture_orders" {...props}>
        {(props) => <ViewMaker fields={ViewOne} {...props}/>}
    </View>
);
