import { Col, Form, Row, Modal, ModalBody, ModalFooter } from "reactstrap";
import { FormikField } from "./index";
import i18n from "i18next";
import classeNames from "classnames";
import { useEffect, useState } from "react";

let index = 0;
const getValues = values => (Array.isArray(values) ? values : []);
const ModalFormik = props => {
  const { fields, value: _values, touched, error, onChange, disabled } = props;
  const values = getValues(_values).map(item => ({ index: index++, ...item }));
  const onAddFormRow = () => {
      const modifiedRows = [...values, { index: index++ }];
      onChange(modifiedRows);
    };
    const [open, setOpen] = useState(false);

  const onDeleteFormRow = index => {
    if (values.length > 0) {
      const modifiedRows = [...values].filter(row => row.index !== index);
      onChange(modifiedRows);
    }
  };

  const RenderDelete = ({ row, index }) => {
    return (
      <Col className="mt-3 p-0">
        <button
          className="btn btn-link p-0 m-0"
          onClick={() => onDeleteFormRow(row.index)}
        >
          <i className="mdi mdi-delete font-size-24 " />
        </button>
      </Col>
    );
  };
  const handleAdd =() =>{
      if(values.length == 0) {
        onAddFormRow()
      }
    setOpen(true)
  }
  useEffect(()=>{
    if(values.length == 0) {
      setOpen(false)
    }
  },[values])

  return (
    <div style={{width: "202px"}}>
      <Modal isOpen size="m" style={{ top: "10%" }} isOpen={open}>
        <ModalBody>
          <Form className="repeater" encType="multipart/form-data">
            <div
              className={classeNames({
                " is-touched": !!touched,
                " is-invalid": !!error,
              })}
            >
              {values.map((row, index) => (
                <>
                  <Row className="align-items-center" key={`row-${index}`}>
                    {fields.map(field => {
                      const handleChange = value => {
                        const item = values.find(
                          item => item.index === row.index
                        );
                        if (item) {
                          const newValues = [
                            ...values.filter(item => item.index !== row.index),
                            {
                              ...item,
                              [field.source]: value,
                            },
                          ].sort((a, b) => a.index - b.index);

                          onChange(newValues);
                        }
                      };
                      return (
                        <Col
                          lg={field.length * 3}
                          className="mb-3"
                          key={`${row.id}-${field.source}`}
                        >
                          <div
                            className={classeNames({
                              " is-touched": !!touched,
                              " is-invalid": !!error,
                            })}
                          >
                            <FormikField
                              values={values}
                              value={row[field.source]}
                              direction="column"
                              disabled={field.disabled}
                              onChange={value => handleChange(value)}
                              {...field}
                            />
                          </div>
                          {Array.isArray(error) &&
                            Array.isArray(touched) &&
                            touched[index] &&
                            error[index] && (
                              <div className="invalid-feedback">
                                {" "}
                                {error[index][field.source]}{" "}
                              </div>
                            )}
                        </Col>
                      );
                    })}
                    <RenderDelete row={row} index={index} />
                  </Row>
                </>
              ))}
            </div>
          </Form>
        </ModalBody>
        <ModalFooter>
          <div className="d-flex justify-content-between" style={{width: "100%"}}>
           
            <div>
            <input
              type="button"
              disabled={
                values.length === 5 && "disabled"

              }
              className="btn btn-success mt-3 mt-lg-0"
              value={i18n.t("add")}
              onClick={() => onAddFormRow()}
            />
            </div>
            <div>
              {" "}
              <input
                type="button"
                disabled={
                  !!fields.find(
                    x => x.source === "order_quantity" && x.disabled === true
                  )
                }
                className="btn btn-primary float-right mt-3 mt-lg-0"
                value={i18n.t("submit")}
                onClick={() => setOpen(false)}
              />
            </div>
          </div>
        </ModalFooter>
      </Modal>
      <div style={{objectFit: "contain"}}>
      <input
        type="button"
        className="btn btn-success mt-3 mt-lg-0"
        value={i18n.t("add")}
        onClick={handleAdd}
      />
      </div>
      <div className="invalid-feedback">{!Array.isArray(error) && error}</div>
    </div>
  );
};

ModalFormik.defaultProps = {
  value: [],
};

export default ModalFormik;
