import {Create, Edit, List, View} from "components/CRUD";
import {DataTable} from "components/Tables";
import {columns, createFields, editFields, filters, subtitle, ViewOne} from "./items.config";
import FormBuilder from "components/FormBuilder";
import ViewMaker from "components/FormBuilder/ViewMaker";
import i18n from "i18n"
export const ItemsList = props => {
    return (
        <List {...props} filters={filters}>
            {({data, ...rest}) =>
                <DataTable resource={props.resource} data={data} columns={columns} {...rest}/>
            }
        </List>
    )
}

export const ItemsCreate = props => {
    return (
        <Create {...props}>
            {(data) => <FormBuilder fields={createFields} {...props} {...data} />}
        </Create>
    );
}

export const ItemsEdit = (props) => (
    <Edit title={i18n.t("edit")} {...props}>
        {(data) => <FormBuilder fields={editFields} {...props} {...data} />}
    </Edit>
);

export const ItemsView = (props) => (
    <View title={i18n.t("view")} resource={props.resource} subtitle={subtitle} url="/items" {...props}>
        {(props) => <ViewMaker fields={ViewOne} {...props}/>}
    </View>
);