import * as Yup from "yup";
import i18n from "i18n";

export const createFields = [
    {
        source: "name",
        label: i18n.t("space_name"),
        validation: Yup.string().required(i18n.t("required_field")).max(50, i18n.t("too_long")),
    },
    {
        source:"domain",
        label: i18n.t("domain"),
    },
    {
        source:"username",
        label: i18n.t("name"),
        validation: Yup.string().required(i18n.t("required_field")).max(50, i18n.t("too_long")),
    },
    {
        source:"email",
        label: i18n.t("admin_email"),
        validation: Yup.string().required(i18n.t("required_field")).max(50, i18n.t("too_long")),
    },
    {
        source: "password",
        label: i18n.t("password"),
        validation: Yup.string().required(i18n.t("required_field")).min(6, i18n.t("too_short")),
        component: "input",
        type: "password"
    }
];
export const editFields = createFields;

export const columns = [
    {source: "name", label: i18n.t("name"), sortable: true},
    {source: "domain", label: i18n.t("domain"), sortable: true},
];

export const ViewOne = [
    {source: "name", label: i18n.t("name")},
    {source: "domain", label: i18n.t("domain")},
];

export const filters= [];


export const title = i18n.t("spaces");
export const subtitle = "";
