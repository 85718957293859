import { useState, useEffect, useCallback } from "react";
import axios, { AxiosResponse, AxiosRequestConfig } from "axios";
import { useQuery } from "hooks";
import useDelete from "./useDelete";

interface Config extends AxiosRequestConfig {
  defaultPage?: number;
  defaultPageSize?: number;
}

interface UseDataTableValue {
  data?: any[];
  total: number;
  loading: boolean;
  error: any;
  page: number;
  pageSize: number;
  searchQuery: string;
  filters: any;
  sort: any;

  refetch: () => void;
  setPage: (page: number) => void;
  setFilters: (page: number) => void;
  setPageSize: (pageSize: number) => void;
  setSearchQuery: (searchQuery: string) => void;
  setSort: (field: any, order: any) => void;

  destroy: (
    resource: string,
    id: number,
    params: any,
    config: AxiosRequestConfig
  ) => void;
}

interface UseDataTableParams {
  url?: string;
  resource?: string;
  headers?: Object;
  params?: Object;
  filters?: Object;
  pagination?: Object;
  config?: Config;
}

export default function useDataTable<T>(
  params: UseDataTableParams
): UseDataTableValue {
  const { resource, url: _url,  headers, config = {}, } = params;

  const url = _url ?? `/${resource}`;

  const [page, setPage] = useState(config.defaultPage ?? 1);
  const [pageSize, setPageSize] = useState(config.defaultPageSize ?? 10);
  const [searchQuery, setSearchQuery] = useState("");
  const [sort, setSort] = useState({ field: "", order: "" });
  const { field, order } = sort;
  const [filters, setFilters] = useState({});

  const { data, loading, error, refetch, response } = useQuery(
    url,
    {
      ...params,
      ...filters,
      take: pageSize,
      skip: (page - 1) * pageSize,
      q: searchQuery,
      field,
      order,
    },
    config,
  );

  const total = parseInt(response?.headers?.["x-total-count"] ?? 0);

  const destroy = useDelete;

  return {
    data,
    total,
    loading,
    error,
    searchQuery,
    page,
    pageSize,
    filters,
    destroy,
    sort,

    refetch,
    setFilters,
    setPage,
    setPageSize,
    setSearchQuery,
    setSort,
  };
}
