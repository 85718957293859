import FiltersActionContainer from "./FiltersActionContainer";
import CRUDActionContainer from "./CRUDActionContainer";

const ActionsContainer = props => {
    const {type} = props;
    
    if (type === 'filters')
        return <FiltersActionContainer {...props} />
    return <CRUDActionContainer {...props} />
}

export default ActionsContainer;