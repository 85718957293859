import React, {useCallback} from "react";
import classeNames from "classnames";

import useAxios from "axios-hooks";
import {AsyncPaginate as AsyncSelect} from "react-select-async-paginate";
import axios from "api_helper";
import i18n from "i18n";

const getData = (data, item, values, distinct) => {
    if (!Array.isArray(data)) return [];

    let options = [];
    if (!Array.isArray(values) || !!distinct) options = data;
    else if (Array.isArray(values)) {
        data.map(option => {
            if (!values.find(value => value.id === option.id)) options.push(option);
        });
    }

    if (item) {
        const found = options.find(e => e.id === item);
        if (!!found) return options;

        return [data.find(e => e.id === item), ...options];
    }

    return options;
};

const WithFetchID = (props) => {
const {
  error: listError,
  loading: listLoading,
  data: listData,
  children,
  resource,
  id,
  distinct,
  values,
} = props
    const error = listError;
    const loading = listLoading;
    const data = getData(listData, id, values, distinct);

    if (typeof children === "function") return children({data, loading, error});
    return children;
};

const getExclude = values =>
    values
        .map(v => v.id)
        .filter(v => !!v)
        .sort((a, b) => a.id - b.id)

const RemoteSelectFormik = (props) => {
  const {
    resource,
    source,
    value,
    values = [],
    touched,
    error: parentError,
    onChange,
    isMulti,
    defaultValue,
    optionImage,
    optionValue,
    distinct,
    disabled,
    optionText,
    transformer,
    fetchFields,
} = props;
    const perPage = 10;
    const sort = {field: optionValue, order: "ASC"};
    const pageUrl = `/${resource}`;

    const [{data: elementData, loading: elementLoading, error: elementError}] =
        useAxios({
            url: `${pageUrl}/${value}`,
            headers: {
                Authorization: `Bearer ${localStorage.token}`,
                "Access-Control-Allow-Origin": "no-cors",
            },
        }, {useCache: true});

    const elementFormatter = useCallback((item, index) => {
        const ret = {
            key: item[optionValue] ?? index,
            label: item[optionText],
            value: item[optionValue],
        };

        if (optionImage && !!item[optionImage]) ret.image = item[optionImage];

        return ret;
    }, []);

    const formatter = useCallback(values => {
        if (values == null || !Array.isArray(values)) return [];
        return values.filter(item => !!item).map(elementFormatter);
    }, []);

    const loadData = useCallback(
        async (query, loadedOptions) => {
            const response = await axios.get(pageUrl, {
                params: {
                    q: query,
                    take: perPage,
                    skip: loadedOptions.length,
                    _order: sort.order,
                    _sort: sort.field,
                    _start: 0,
                    exclude: getExclude(values),
                    fields: fetchFields,
                },
            });

            return {
                options: formatter(response.data),
                hasMore: response.headers["X-Total-Count"] < loadedOptions.length,
            };
        },
        [values]
    );

    const error = parentError;
    const loading = elementLoading;
    const selectedOption = elementData ? elementFormatter(elementData) : null;
    const options = [];
    const unit = elementData?.unit;

    const Wrapper = WithFetchID;

    return (
        <Wrapper
            data={[]}
            error={error}
            loading={loading}
            distinct={distinct}
            resource={resource}
            values={values}
            id={value}
        >
            {({loading, error}) => {

                return (
                    <div className="d-flex align-items-center">
                        {unit && (
                            <span style={{margin: "0px 8px"}}>{unit.substring(0, 6)}</span>
                        )}
                        <div style={{flex: 1}}>
                            <AsyncSelect
                                key={Math.random()}
                                source={source}
                                touched={touched}
                                isMulti={isMulti}
                                className={classeNames("basic-single", {
                                    " is-touched": !!touched,
                                    " is-invalid": !!error,
                                    " reactselect-invalid": !!error,
                                })}
                                isClearable
                                loading={loading}
                                disabled={loading || disabled}
                                defaultValue={value}
                                useCache={false}
                                value={selectedOption}
                                defaultOptions={options}
                                placeholder={i18n.t("select")}
                                options={options}
                                loadOptions={loadData}
                                onChange={(e, action) => {
                                    typeof onChange === "function" && onChange(e.value, action);
                                }}
                            />
                            {error && <div className="invalid-feedback">{error}</div>}
                        </div>
                    </div>
                );
            }}
        </Wrapper>
    );
};

RemoteSelectFormik.defaultProps = {
    transformer: values => values,
    optionText: "name",
    optionValue: "id",
    optionImage: "image",
    distinct: false,
    fetchFields: ["title", "id"],
};

export default RemoteSelectFormik;
