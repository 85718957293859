import React from "react";

const InputErrorFormik = ({ error }) => {
  if (typeof error === "object" && error) {
    return (
      <div className="invalid-feedback">
          {Object.values(error)[0]}
      </div>
    );
  }

  return <div className="invalid-feedback">{error}</div>;
};

export default InputErrorFormik;
