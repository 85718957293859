import {email, PasswordInput, required, SimpleForm, TextField, TextInput} from "react-admin";
import React from "react";

import {Create, Edit, List, View} from "components/CRUD";
import {DataTable} from "components/Tables";
import FormBuilder from "components/FormBuilder";
import {subtitle, createFields, editFields, filters,ViewOne, columns} from "./users.config";
import i18n from "i18n";

import ViewMaker from "components/FormBuilder/ViewMaker";


export const UsersList = props => {
    
    return (
        <List {...props} filters={filters}>
            {({data, ...rest}) =>
                <DataTable resource={props.resource} data={data} columns={columns} {...rest}/>
            }
        </List>
    )
}

export const UsersCreate = props => {
  return (
      <Create {...props}>
          {(data) => <FormBuilder fields={createFields} {...props} {...data} />}
      </Create>
  );
}

export const UsersEdit = (props) => (
    <Edit title={i18n.t("edit")} {...props}>
        {(data) => <FormBuilder fields={editFields} {...props} {...data} />}
    </Edit>
);

export const UsersView = (props) => (
    <View title={i18n.t("view")} url="/users" {...props}>
        {(props) => <ViewMaker resource={props.resource} fields={ViewOne} {...props}/>}
    </View>
);
