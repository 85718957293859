import { Progress } from 'reactstrap';
import i18n from 'i18n';
const ProgressBar = ({ value, target }) => {
    return (
        <div style={{width:"25%"}}> 
            <div className="text-center">{parseFloat(value).toFixed(2)}%</div>
            <Progress   value={parseFloat(value).toFixed(2)}/>
        </div>
    )
}
export default ProgressBar;