import Select from "react-select";
import classeNames from "classnames";
import i18n from "i18next";

const colourStyles = {
  singleValue: styles => ({ ...styles, color: "#495057"  }),
  menuPortal: base => ({ ...base, zIndex: 9999 })
};

const SelectFormik = props => {
  const {
    source,
    value,
    values,
    touched,
    error,
    type,
    distinct,
    onChange,
    isMulti,
    options,
    disabled,
    defaultValue,
    loading,
  } = props;
  const handleChange = (e, action) =>
    typeof onChange === "function" && onChange(e.value, action);
  let selectedOption = options.find(option => option.value === value);
  let optionsModified = []
  if(!!distinct) {
    options.map(e => {
      if(!values.find(value => value.id === e.value)) optionsModified.push(e) 
    })
  }else {
    optionsModified = options
  }
  return (
    <>
        <Select
          name={source}
          value={selectedOption}
          type={type}
          isMulti={isMulti}
          options={optionsModified }
          error={error}
          isLoading={loading}
          isDisabled={disabled}
          className={classeNames("basic-single", {
            " is-touched": !!touched,
            " is-invalid": !!error,
            " reactselect-invalid": !!error,
          })}
          menuPortalTarget={document.body} 
          styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
          onChange={handleChange}
          classNamePrefix="select2-selection"
          isSearchable
          getOptionLabel={option => option.label}
          // styles={colourStyles}
          placeholder={i18n.t("select")}
        />
      <div className="invalid-feedback">{error}</div>
    </>
  );
};

SelectFormik.defaultProps = {
  options: [],
};

export default SelectFormik;
