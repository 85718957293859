import {Create, Edit, List, View} from "components/CRUD";
import {DataTable} from "components/Tables";
import FormBuilder from "components/FormBuilder";
import {
    createFields,
    editFields,
    CustomEditFields,
    filters,
    subtitle,
    title,
    columns,
    ViewOne
} from "./purchase_orders.config";
import i18n from "i18n";
import ViewMaker from "components/FormBuilder/ViewMaker";

export const PurchaseOrdersList = props => {
    return (
        <List {...props} title={i18n.t(title)} filters={filters}>
            {({data, ...rest}) =>
                <DataTable resource={props.resource} data={data} columns={columns} {...rest} />
            }
        </List>
    )
}

export const PurchaseOrdersCreate = props => {
    return (
        <Create {...props} >
            {(data) => <FormBuilder fields={createFields} method="create" {...props} {...data} />}
        </Create>
    );
}

export const PurchaseOrdersEdit = (props) => {
    return (
        <Edit title={i18n.t("edit")} url="/purchase_orders" resource={props.resource}   {...props}>
            {(data) => <FormBuilder fields={editFields} title="purchase_orders" Customfields={CustomEditFields}
                buttonLabel="Submit" {...props} {...data} />}
        </Edit>
    )
};

export const PurchaseOrdersView = (props) => {
    console.log(props)
 return <View title={i18n.t("view")} resource={props.resource} subtitle={subtitle} url="/products" {...props}>
        {(props) => <ViewMaker fields={ViewOne} {...props}/>}
    </View>
};
