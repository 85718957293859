import * as Yup from "yup";
import i18n from "i18n";
import classNames from "classnames"

export const createFields = [
    {
        source: 'description',
        label: i18n.t('description'),
        validation: Yup.string().required(i18n.t("required_field")),
    },
    {
        source: 'products',
        label: i18n.t('products'),
        validation: Yup.array().required(i18n.t("required_field")).of(
            Yup.object().shape({
              id: Yup.number().typeError(i18n.t("this_field_must_be_a_number")).required(i18n.t("required_field")),
              quantity: Yup.number().typeError(i18n.t("this_field_must_be_a_number")).required(i18n.t("required_field")),
              deliver_date: Yup.date().typeError(i18n.t("this_field_must_be_a_date")).required(i18n.t("required_field")),
            })
          ),
        component: 'array',
        fields: [{
            source: 'id',
            label: i18n.t('product'),
            component: 'select',
            type: 'remote',
            resource: 'products',
            optionLabel: 'name',
            optionValue: 'id',
            initialValue: ({product: {id}}) => id,
        }, {
            source: 'quantity',
            label: i18n.t('quantity'),
        }, {
            source: 'deliver_date',
            label: i18n.t('deliver_date'),
            component: 'date',
        }],
    }
];
export const editFields = createFields;

export const columns = [
    { source: 'id', label: i18n.t('id'), sortable: true },
    {source: 'owner.name', label: i18n.t('order_owner'), sortable: true},
    {
        source: 'progress', label: i18n.t('progress'), renderer: (progress) => <div className="text-center">
            <small className="text-dark">{parseFloat(progress).toFixed(2)}%</small>
            <div className="progress">
                <div className="progress-bar" role="progressbar" style={{ "width": `${parseFloat(progress).toFixed(2)}%` }} className={classNames("progress-bar", {
                    "bg-danger": parseFloat(progress).toFixed(2) < 50,
                    "bg-warning": parseFloat(progress).toFixed(2) < 100 && parseFloat(progress).toFixed(2) >= 50,
                    "bg-success": parseFloat(progress).toFixed(2) == 100,
                })} aria-valuemin="0" aria-valuemax="100" />
            </div>
        </div>
    },
    {
        source: 'status',
         label: i18n.t('status'), renderer: (status) => <div className={classNames("badge text-white", {
            "bg-success": status === "done",
            "bg-warning": status === "waiting",
            "bg-primary": status === "in progress",
            "bg-danger": status === "blocked" || status ==='suspended',
        })}>
            {i18n.t(status)}
        </div>
    },
];

export const ViewOne = [
    { source: 'id', label: i18n.t('id') },
    { source: 'owner', label: i18n.t('order_owner'), component: "object", target: "name",},
    { source: 'description', label: i18n.t('description') },
    { source: 'status', label: i18n.t('status') },
    { source: 'can_approve', label: i18n.t('can_be_approved') },
    { source: 'approved_at', component: 'date', label: i18n.t('approve_at') },
    { source: 'created_at', component: 'date', label: i18n.t('created_at') },
    { source: 'start_at', component: 'date', label: i18n.t('start_at') },
    { source: 'progress', component: 'progress', label: i18n.t('progress') },
    {
        source: 'products', count: "total_products", direction: 'col', component: 'table', columns: [
            {source: 'name', label: i18n.t('name'), sortable: true},
            {source: 'description', label: i18n.t('description'), sortable: true},
            {source: 'category.name', label: i18n.t('category_name'), sortable: true},
            {source: 'unit', label: i18n.t('unit'), sortable: true},
            {source: 'deliver_date', label: i18n.t('favourite_deliver_date'), sortable: true},
            {source: 'quantity', label: i18n.t('quantity'), sortable: true},
        ]
    }
]


export const filters = [
    {
        label: i18n.t('status'),
        source: 'status',
        component: 'select',
        optionText: 'label',
        container: 'filters',
        direction: 'column',
        options: [
            {value: 'blocked', label: i18n.t('blocked')},
            {value: 'waiting', label: i18n.t('waiting')},
            {value: 'in progress', label: i18n.t('in progress')},
            {value: 'done', label: i18n.t('done')},
            {value: 'suspended', label: i18n.t('suspended')},
        ]
    },
    {
        source: 'min_progress',
        label: i18n.t('progress'),
        direction: 'column',
        component: 'input',
        defaultValue: [0, 100],
        type: 'range',
        container: 'filters',
        validation: Yup.number(),
    },
    {
        source: 'products_count',
        direction: 'column',
        label: i18n.t('products_count'),
        defaultValue: [0, 100],
        component: 'input',
        type: 'range',
        container: 'filters',
        validation: Yup.number(),
    },
    {
        source: 'deliver_date',
        direction: 'column',
        label: i18n.t('deliver_date'),
        component: 'date',
        type: 'range',
        container: 'filters',
    },
];

export const title = i18n.t("orders");
export const subtitle = "";