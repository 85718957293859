import i18n from 'i18n'
import PropTypes from 'prop-types'
import React from "react"
import {
  CardTitle,
} from "reactstrap"
const RenderCardTitle = props => {
  return (
    <React.Fragment>
      <CardTitle className="mb-4 text-center">{i18n.t(props.title)}</CardTitle>
    </React.Fragment>
  )
}

RenderCardTitle.propTypes = {
  title: PropTypes.string
}

export default RenderCardTitle
