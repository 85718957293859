import * as Yup from "yup";
import i18n from "i18n";

export const createFields = [
    {
        source: 'name',
        label: i18n.t('name'),
        validation: Yup.string().required(i18n.t("required_field")).min(1, i18n.t('too_short')).max(50, i18n.t('too_long')),
    },
    {
        source: 'description',
        label: i18n.t('description'),
        validation: Yup.string().required(i18n.t("required_field")).min(3, i18n.t('too_short')),
    },
    {
        source: 'image',
        label: i18n.t('image'),
        type: 'file',
        component: 'file',
    }
];

export const columns = [
    {source: 'name', label: i18n.t('name'), sortable: true},
];

export const ViewOne = [
    {source: 'name', label: i18n.t('name')},
    {source: 'description', label: i18n.t('description')},
    {source: 'products_total_count', label: i18n.t('products_total_count')},
    {source: 'image', label: i18n.t('image'), component: "image"},
];

export const filters = [];

export const editFields = createFields;

export const title = i18n.t("categories");
export const subtitle = ""
