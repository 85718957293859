

import {Create, Edit, List,  View} from "components/CRUD";
import {DataTable} from "components/Tables";
import FormBuilder from "components/FormBuilder";
import {editFields, createFields, subtitle, filters, columns, ViewOne} from "./categories.config";
import ViewMaker from "components/FormBuilder/ViewMaker";
import i18n from "i18n"


export const CategoriesList = props => {
    return (
        <List {...props} filters={filters}>
            {({data, ...rest}) =>
                <DataTable resource={props.resource} data={data} columns={columns} {...rest}/>
            }
        </List>
    )
}

export const CategoriesCreate = props => {
    return (
        <Create {...props}>
            {(data) => <FormBuilder fields={createFields} {...props} {...data} />}
        </Create>
    );
}

export const CategoriesEdit = (props) => (
    <Edit title={i18n.t("edit")} {...props}>
        {(data) => <FormBuilder fields={editFields} {...props} {...data} />}
    </Edit>
);



export const CategoriesView = (props) => (
    <View title={i18n.t("view")} resource={props.resource} subtitle={subtitle} url="/categories" {...props}>
        {(props) => <ViewMaker fields={ViewOne} {...props}/>}
    </View>
);