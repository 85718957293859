import { capitalCase } from "capital-case";
import React from "react";
import classes from 'classnames';

import {
    ArrayFormik,
    InputContainerFormik,
    InputErrorFormik,
    InputFormik,
    LabelFormik,
    RemoteSelectFormik,
    SelectFormik,
    DateFormik,
    DateRangeFormik,
    InputRangeFormik,
    PasswordFormik,
    CustomCheckBoxGrid,
    CustomMap,
    timeStampFormik,
    FileUploaderFormik,
    ModalFormik
} from "./index";

export const getInput = (component, type) => {
    if (typeof component === 'function')
        return component;

    switch (component) {
        case 'input':
            switch (type) {
                case 'range':
                    return InputRangeFormik
                case 'password':
                    return PasswordFormik;
                default:
                    return InputFormik;
            }
        case 'modal':
            return ModalFormik;
        case 'select':
            switch (type) {
                case 'remote':
                    return RemoteSelectFormik;
                default:
                    return SelectFormik;
            }
        case 'date':
            switch (type) {
                case 'range':
                    return DateRangeFormik
                default:
                    return DateFormik;
            }
        case 'timestamp':
            return timeStampFormik
        case 'array':
            return ArrayFormik;

        case 'list':
            return CustomCheckBoxGrid;

        case 'map':
            return CustomMap;
        case 'file':
            return FileUploaderFormik;
        default:
            return InputFormik;
    }
}

const FieldFormik = ({ component, type, source, touched, error, label: _label, direction,disabled,showErrorWithoutTouch, ...rest }) => {
    const InputFormik = getInput(component, type);
    const label = _label ?? capitalCase(source);
    return (
        <React.Fragment>
            <LabelFormik source={source} className={classes({ "col-md-2": direction === 'row' })}> {label} </LabelFormik>
            <InputContainerFormik className={classes({ "col-md-10": direction === 'row' })}>
                <InputFormik className={classes("basic-single", {
                  " is-touched": !!touched,
                  " is-invalid": !!error,
                })} source={source} disabled={disabled} type={type} error={touched && error} touched={touched} {...rest} />
               {((touched && !Array.isArray(error)) ||(showErrorWithoutTouch)) && <InputErrorFormik error={error} />}
            </InputContainerFormik>
        </React.Fragment>
    );
}

FieldFormik.defaultProps = {
    direction: 'row',
};

export default FieldFormik;