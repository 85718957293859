import React, { useEffect, useState } from "react"
import MetaTags from 'react-meta-tags';
import api from "api_helper"
import i18n from "i18n"
import { useHistory } from "react-router";
import toastr from "toastr";

const permUrl = '/permissions?take=200';
const CustomCheckBoxGrid = (props) => {
    const history = useHistory();

    const [values, setValues] = useState([])
    const [users, setUsers] = useState([])
    const [warehouses, setWarehouses] = useState([])
    const [pipelines, setPieplines] = useState([])
    const [categoires, setCategoires] = useState([])
    const [items, setItems] = useState([])
    const [tasks, setTasks] = useState([])
    const [products, setProducts] = useState([])
    const [purcahseOrders, setPurcahseOrders] = useState([])
    const [stages, setStages] = useState([])
    const [orders, setOrders] = useState([])
    const [permissions, setPermissions] = useState([])
    const [others, setOthers] = useState([])
    const { onChange, value } = props;

    useEffect(async () => {
        const res = await api.get(permUrl)

        res?.data?.map(e => {
            e.checked = value && value?.find(s => s.id == e.id) ? true : false;
        })
        setValues(res && res.data)
    }, [])

    return (
        <React.Fragment>
            <div className="">
                <MetaTags>
                    <title>{i18n.t("permissions")}</title>
                </MetaTags>
                <div style={{width:"90%", }} className="grid">
                    {values?.map((e, index) => {
                        return <div key={e.id}  className="d-flex align-items-center">
                            <input style={{margin:"7px"}} type="checkbox" checked={e.checked} value={e.checked} onChange={() => onChange(e)} id="" />
                            <div>{i18n.t(e.name)}</div>
                        </div>
                    })}
                </div>
            </div>
        </React.Fragment>
    )
}

export default CustomCheckBoxGrid