import PropTypes from "prop-types";
import React from "react";
import { Link } from "react-router-dom";
import classNames from "classnames";
import { Card, CardBody, CardTitle } from "reactstrap";
import { map, size } from "lodash";
import { Spinner } from "reactstrap";

//Import Images
import images from "assets/images";

import { statusClasses } from "common/data/tasks";
import i18n from "i18n";

const CardTaskBox = props => {
  const { data } = props;
  const viewUrl = `/tasks/:id/show`;
  return (
    <React.Fragment>
      <Card className="task-box">
        <CardBody className="borad-width">
          {data?.loading ? (
            <div
              className="d-flex justify-content-center align-items-center ml-3"
              style={{ minHeight: "100px" }}
            >
              <Spinner
                color={classNames(
                  {
                    danger: data.status === "blocked",
                    warning: data.status === "waiting",
                    primary: data.status === "in progress",
                    success: data.status === "done",
                  },
                  statusClasses[data.status.toLowerCase()]
                )}
              />
            </div>
          ) : (
            <>
              <CardTitle className="d-flex justify-content-between align-items-center text-dark font-size-20">
                <span>{data.title}</span>
                <Link
                  className="text-primary font-size-12"
                  to={viewUrl.replace(":id", data.id)}
                >
                  <i
                    className={`mdi mdi-information-outline font-size-24`}
                  />
                </Link>
              </CardTitle>
              <div className="float-end ms-2">
                <span
                  className={classNames(
                    "badge rounded-pill badge-soft-secondary text-white font-size-12",
                    {
                      "bg-danger": data.status === "blocked",
                      "bg-warning": data.status === "waiting",
                      "bg-primary": data.status === "in progress",
                      "bg-success": data.status === "done",
                    },
                    statusClasses[data.status.toLowerCase()]
                  )}
                >
                  {i18n.t(data.status)}
                </span>
              </div>

              <div>
                <h5 className="font-size-15">
                  <Link to="#" className="text-secondary">
                    {data.description}
                  </Link>
                </h5>
                <p className="text-muted mb-4">{data.dueDate}</p>
              </div>

              <div className="avatar-group float-start">
                {map(
                  data.members,
                  (member, index) =>
                    index < 2 && (
                      <div className="avatar-group-item" key={index}>
                        <Link to="#" className="d-inline-block">
                          {member.userImg ? (
                            <img
                              src={images[member.userImg]}
                              className="rounded-circle avatar-xs"
                              alt=""
                            />
                          ) : (
                            <div className="avatar-xs">
                              <span className="avatar-title rounded-circle bg-info text-white font-size-16">
                                {member.username.charAt(0)}
                              </span>
                            </div>
                          )}
                        </Link>
                      </div>
                    )
                )}
                {size(data.members) > 2 && (
                  <div className="avatar-group-item">
                    <Link to="#" className="d-inline-block">
                      <div className="avatar-xs">
                        <span className="avatar-title rounded-circle bg-info text-white font-size-16">
                          {size(data.members) - 2} +
                        </span>
                      </div>
                    </Link>
                  </div>
                )}
              </div>

              <div className="text-end">
                <p className="text-start mb-0 font-size-15 mb-1 text-muted">
                  {data.manager}
                </p>
                
              </div>
            </>
          )}
        </CardBody>
      </Card>
    </React.Fragment>
  );
};

CardTaskBox.propTypes = {
  data: PropTypes.object,
};

export default CardTaskBox;
