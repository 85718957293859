import classNames from "classnames"
import i18n from "i18n";
const Description = ({ value, source }) => {
    return (
        <div className="text-secondary font-size-14">
            {source.includes("status") ? <div className={classNames("badge text-white", {
                "bg-success": value === "done",
                "bg-danger": value === "suspended" || value === "blocked",
                "bg-primary": value === "in progress",
                "bg-warning": value === "waiting"
            })}>
                {i18n.t(value)}
            </div> : <div>{value == false ? i18n.t(value) : value == true ? i18n.t(value) : value}</div>}
        </div>
    )
}

export default Description;