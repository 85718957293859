import { useEffect } from "react";
import { compose, withStateHandlers } from "recompose";
import {
  withScriptjs,
  withGoogleMap,
  GoogleMap,
  Marker,
} from "react-google-maps";
import classeNames from "classnames"
const Map = compose(
  withStateHandlers(
    () => ({
      isMarkerShown: false,
      markerPosition: null,
    }),
    {
      onMapClick:
        ({ isMarkerShown }) =>
        (e, onChange) => {
          return {
            markerPosition: e.latLng,
            isMarkerShown: true,
          };
        },
    }
  ),
  withScriptjs,
  withGoogleMap
)(props => {
  useEffect(() => {
    if (props.isMarkerShown) {
      props.onChange(props.markerPosition);
    } else {
      props.onChange(props.value);
    }
  }, [props.markerPosition]);

  return (
    <div className="d-flex mb-5 " className={props.className}>
      <GoogleMap
        defaultZoom={8}
        defaultCenter={props.value || { lat: 10, lng: 10 }}
        onClick={props.onMapClick}
        >
        {(props.isMarkerShown || props.value) && (
          <Marker position={props.value} />
          )}
          

      </GoogleMap>
    </div>
  );
});

const MapContainer = props => {
  return (
    <div className={props.className}>
      <Map
        onChange={props.onChange}
        source={props.source}
        value={props.value}
        {...props}
        googleMapURL={`https://maps.googleapis.com/maps/api/js?key=${process.env.REACT_APP_key}&v=3.exp&libraries=geometry,drawing,places`}
        loadingElement={<div style={{ height: `100%` }} />}
        containerElement={<div style={{ height: `500px` }} />}
        className={classeNames("basic-single", {
          " is-touched": !!props.touched,
          " is-invalid": !!props.error,
          " reactselect-invalid": !!props.error,
        })}
        mapElement={<div style={{ height: `100%` }} />}
      />
    </div>
  );
};

export default MapContainer;
