import classes from "classnames";
import React from "react";

const InputFormik = ({source, value, touched, error, type, disabled, onChange, defaultValue}) => {
  return (
    <input
      id={source}
      name={source}
      value={value}
      type={type}
      disabled={disabled}
      className={classes("form-control", {"is-touched": !!touched, "is-invalid": !!error})}
      defaultValue={defaultValue}
      onChange={(e) => typeof onChange === 'function' && onChange(e.target.value)}
    />
  );
}

export default InputFormik;