import * as Yup from "yup";
import i18n from "i18n";

export const createFields = [
    {
        source: "name",
        label: i18n.t("name"),
        validation: Yup.string().required(i18n.t("required_field")).max(50, i18n.t("too_long")),
    },
    {
        source: "description",
        label: i18n.t("description"),
        validation: Yup.string().required(i18n.t("required_field")),
    },
    {
        source: "category_id",
        label: i18n.t("category_name"),
        component: "select",
        type: "remote",
        resource: "categories",
        optionLabel: "name",
        optionValue: "id",
        initialValue: ({category: {id}}) => id,
        validation: Yup.number().required(i18n.t("required_field")),
    },
    {
        source: "pipeline_id",
        label: i18n.t("pipeline_name"),
        component: "select",
        type: "remote",
        resource: "pipelines",
        optionLabel: "name",
        optionValue: "id",
        initialValue: ({pipeline: {id}}) => id,
        validation: Yup.number().required(i18n.t("required_field")),
    },
    {
        source: "unit",
        label: i18n.t("unit"),
        validation: Yup.string().required(i18n.t("required_field")),
    },
    {
        source: "image",
        label: i18n.t("image"),
        type: "file",
        component: "file",
    },
    {
        source: "items",
        label: i18n.t("items"),
        component: "array",
        validation: Yup.array().required(i18n.t("required_field")).of(
            Yup.object().shape({
                id: Yup.number().typeError(i18n.t("this_field_must_be_a_number")).required(i18n.t("required_field")),
                quantity: Yup.number().typeError(i18n.t("this_field_must_be_a_number")).required(i18n.t("required_field")),
            })
        ),
        fields: [
            {
                source: "id",
                label: i18n.t("item"),
                component: "select",
                type: "remote",
                resource: "items",
                optionLabel: "name",
                optionValue: "id",
                initialValue: ({manager: {id}}) => id,
            },
            {
                source: "quantity",
                label: i18n.t("quantity"),
            },
        ],
    },
];
export const editFields = createFields;

export const columns = [
    {source: "name", label: i18n.t("name"), sortable: true},
    {source: "unit", label: i18n.t("unit"), sortable: true},
    {source: "category.name", label: i18n.t("category_name"), sortable: true},
    {source: "pipeline.name", label: i18n.t("pipeline_name"), sortable: true},
];

export const ViewOne = [
    {source: "name", label: i18n.t("name")},
    {source: "description", label: i18n.t("description")},
    {
        source: "category",
        label: i18n.t("category"),
        component: "object",
        target: "name",
    },
    {
        source: "pipeline",
        label: i18n.t("pipeline"),
        component: "object",
        target: "name",
    },
    {source: "unit", label: i18n.t("unit")},
    {source: "image", label: i18n.t("image"), component: "image"},
    {
        source: "items",
        component: "table",
        label: i18n.t("items"),
        direction: "column",
        count: "total_items",
        columns: [
            {source: "name", label: i18n.t("name"), sortable: true},
            {source: "unit", label: i18n.t("unit"), sortable: true},
            {source: "quantity", label: i18n.t("quantity"), sortable: true},
            {source: "description", label: i18n.t("description"), sortable: true},
        ],
    },
];

export const filters = [];
if (localStorage?.permissions?.includes("list_all_categories")) {
    filters.push({
        source: "category_id",
        label: i18n.t("category"),
        component: "select",
        type: "remote",
        resource: "categories",
        optionLabel: "category_id",
        optionValue: "id",
        initialValue: ({category_id}) => category_id,
        container: "filters",
        direction: "column",
        validation: Yup.number().required(i18n.t("required_field")),
    });
}

export const title = i18n.t("products");
export const subtitle = "";
