import { useState } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import paginationFactory from "react-bootstrap-table2-paginator";
import { Link } from "react-router-dom";
import "../../../Tables/datatables.scss";
import classNames from "classnames";
import i18n from "i18n";
import { Alert, Spinner, Col } from "reactstrap";
import { useDataTable } from "hooks";

import { ErrorBoundary } from "react-error-boundary";

function ErrorFallback(props) {
  const { error, resetErrorBoundary } = props;
  return (
    <div role="alert">
      <p>Something went wrong:</p>
      <pre>{error.message}</pre>
      <button onClick={resetErrorBoundary}>Try again</button>
    </div>
  );
}

const Datatable = props => {
  const [error, setError] = useState(null);

  const { value, columns, loading, resource, total, source, searchQuery } =
    props;
  const url = `/${resource}/${props.data.id}/${source}`;

  const table = useDataTable({ url });

  let col = [];
  const { SearchBar } = Search;
  const onSearch = q => {
      table.setSearchQuery(q);
  };

  if (table.error) {
    return <Alert color="danger">{i18n.t(table.error.message)}</Alert>;
  }

  const paginationConfig = {
    keyField: "datatable-pagination",
    totalSize: total,
    page: table.page,
    sizePerPage: table.pageSize,
    onPageChange: page => {
      table.setPage(page);
    },
    onSizePerPageChange: pageSize => {
      table.setPageSize(pageSize);
    },
  };

  columns.map(column => {
    col.push({
      dataField: column.source,
      text: column.label,
      formatter:
        column?.renderer ??
        function (value) {
          if (column.source.includes("status")) {
            return (
              <div
                className={classNames("badge text-white", {
                  "bg-danger": value === "blocked",
                  "bg-warning": value === "waiting",
                  "bg-primary": value === "in progress",
                  "bg-success": value === "done",
                })}
              >
                {i18n.t(value)}
              </div>
            );
          }
          return <span>{value}</span>;
        },
    });
  });
  const viewUrl = `/${source}/:id/show`;

  const renderView = row => {
    return (
      <Link className="text-primary" to={viewUrl.replace(":id", row.id)}>
        <i
          className={`mdi mdi-arrow-${
            localStorage?.I18N_LANGUAGE == "ar" ? "left" : "right"
          }-bold font-size-24`}
        />
      </Link>
    );
  };

  col.push({
    dataField: "view",
    isDummyField: true,
    editable: false,
    text: i18n.t("view"),
    formatter: (cellContent, row) => (
      <div className="d-flex gap-3">{renderView(row)}</div>
    ),
  });
  return (
    <div>
      <ErrorBoundary FallbackComponent={ErrorFallback}>
        <ToolkitProvider
          search
          keyField="name"
          columns={col}
          data={table.data ?? []}
          classes={"table align-middle table-nowrap"}
          bodyStyle={{ wordBreak: "break-all" }}
          headerWrapperClasses={"c-pointer"}
          rowStyle={{ whiteSpace: "pre-wrap", cursor: "default" }}
        >
          {props => {
            return (
              <div className="d-flex flex-column">
                <Col md={4}>
                  <div className="search-box me-2 mb-2 d-inline-block">
                    <div className="position-relative">
                      <SearchBar
                        searchText={table.searchQuery}
                        onSearch={onSearch}
                        placeholder={i18n.t("search")}
                        style={{ width: "20rem" }}
                      />
                      <i className="bx bx-search-alt search-icon" />
                    </div>
                  </div>
                </Col>

                <BootstrapTable
                  key="name"
                  keyField="name"
                  remote
                  responsive
                  data={table.data ?? []}
                  columns={col}
                  loading={table.loading}
                  bordered={false}
                  striped={false}
                  headerWrapperClasses={"thead-light"}
                  pagination={paginationFactory(paginationConfig)}
                  sizePerPageList={props.sizePerPageList}
                  onTableChange={console.log}
                  classes={"table table-nowrap"}
                  bodyStyle={{ wordBreak: "break-all" }}
                  rowStyle={{ whiteSpace: "pre-wrap", cursor: "default" }}
                  {...props.baseProps}
                />
              </div>
            );
          }}
        </ToolkitProvider>
      </ErrorBoundary>
    </div>
  );
};

Datatable.defaultProps = {
  defaultSorted: [
    {
      dataField: "id",
      order: "asc",
    },
  ],
  sizePerPageList: [
    { text: "5", value: 5 },
    { text: "10", value: 10 },
    { text: "15", value: 15 },
    { text: "20", value: 20 },
    { text: "25", value: 25 },
  ],
  keyField: "id",
  data: [],
};
export default Datatable;
