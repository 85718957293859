import React from "react";
import ReactApexChart from "react-apexcharts";
import i18n from "i18n";

const PieChartData = ({ data, title }) => {
  const series = [parseFloat(data?.other?.toFixed(2))];
  const labels = [i18n.t("other")];
  
  data && data?.top3.map(e => {
      series.push(parseFloat(e.consumption.toFixed(2)));
      labels.push(e.item.name);
    });
  const state = {
    series,
    options: {
      title: {
        text: i18n.t(title),
        align: "center",
      },
      labels,
      responsive: [
        {
          breakpoint: 2,
          options: {
            chart: {
              width: 450,
              height: 320,
            },
            legend: {
              position: "bottom",
            },
          },
        },
      ],
    },
  };
  return (
    <React.Fragment>
      <ReactApexChart
        options={state.options}
        title={title}
        series={state.series}
        type="pie"
      />
    </React.Fragment>
  );
};

export default PieChartData;
