import i18n from "i18n";
const ArrayObject = ({value, target}) => {
    return (
        <div className="text-secondary font-size-14 grid">
            {value.length > 0 && value.map(item => {
              return <div key={item.id}>{i18n.t(item[target])}</div>
            })}
        </div>
    )
}
export default ArrayObject;