import {Button, Spinner} from "reactstrap";
import React from "react";
import i18n from "i18n";
import {useHistory} from "react-router";
import { clearData } from "../../../../store/purchaseOrder/actions";
import {connect} from "react-redux"

import Swal from "sweetalert2";
import toastr from "toastr";


const CRUDActionContainer = props => {
    const {loading, handleSubmit, clearData} = props;
    const {resource} = props;

    const history = useHistory();

    const handleAbort = () => {
        Swal.fire({
            text: i18n.t('are_you_sure') + i18n.t('?'),
            title: i18n.t('your_changes_will_not_be_saved'),
            icon: 'warning',
            showConfirmButton: true,
            showDenyButton: true,

            denyButtonText: i18n.t('abort'),
            confirmButtonText: i18n.t('stay_here')
        }).then((result) => {
            if (result.isDenied) {
                history.push("/" + resource);
                if(resource === 'purchase_orders'){
                    clearData();
                }
            }
        });
    }

    return (
        <div className="d-flex flex-wrap gap-2 ">
            {resource !== "neediness_calculator" && <Button disabled={loading} style={{padding:"10px 1.3em" }} type='button' color='danger'
                    onClick={handleAbort}> {i18n.t('abort')} </Button>}
            <Button disabled={loading} type='submit' color='primary' style={{padding:"10px 2em" }}
                    onChange={handleSubmit}> { loading ? <>{<Spinner style={{width:"1.3em", height:"1.3em"}} /> }</> :i18n.t('submit')} </Button>
        </div>
    );
}
const mapStateToProps = (state) => ({
  
})

export default connect (mapStateToProps, {clearData})(CRUDActionContainer);