import {applyMiddleware, combineReducers, compose, createStore} from 'redux';
import {connectRouter, routerMiddleware} from 'connected-react-router';
import createSagaMiddleware from 'redux-saga';
import {all, fork} from 'redux-saga/effects';
import {adminReducer, adminSaga, USER_LOGOUT, fetchUtils} from 'react-admin';
import AccountSaga from "./store/auth/register/saga";
import AuthSaga from "./store/auth/login/saga";
import ForgetSaga from "./store/auth/forgetpwd/saga";
import ProfileSaga from "./store/auth/profile/saga";
import LayoutSaga from "./store/layout/saga";
import dashboardSaga from "./store/dashboard/saga";
import {createBrowserHistory, createHashHistory} from "history";
import jsonServerProvider from "ra-data-json-server";
import Layout from "./store/layout/reducer";
import Login from "./store/auth/login/reducer";
import Account from "./store/auth/register/reducer";
import ForgetPassword from "./store/auth/forgetpwd/reducer";
import Profile from "./store/auth/profile/reducer";
import Dashboard from "./store/dashboard/reducer";
import purchaseOrder from './store/purchaseOrder/purchaseOrder';

const fetchJson = async(url, options = {}) => {
  if (!options.headers) {
      options.headers = new Headers({ Accept: 'application/json' });
  }
  // add your own headers here
  options.headers.set('Authorization', `Bearer ${localStorage.token}`);
  options.headers.set('Access-Control-Allow-Origin', 'no-cors');
  return fetchUtils.fetchJson(url, options);
}

export default ({authProvider, dataProvider, history}) => {

  const reducer = combineReducers({
    Layout,
    Login,
    Account,
    ForgetPassword,
    Profile,
    Dashboard,
    purchaseOrder,

    admin: adminReducer,
    router: connectRouter(history),
  });

  const resettableAppReducer = (state, action) => reducer(action.type !== USER_LOGOUT ? state : undefined, action);

  const saga = function* rootSaga() {
    yield all(
      [
        adminSaga(dataProvider, authProvider),
        AccountSaga,
        AuthSaga,
        ForgetSaga,
        ProfileSaga,
        LayoutSaga,
        dashboardSaga,
      ].map(fork)
    );
  };
  const sagaMiddleware = createSagaMiddleware();

  const composeEnhancers =
    (process.env.NODE_ENV === 'development' && typeof window !== 'undefined' &&
      window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ &&
      window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
        trace: true,
        traceLimit: 25,
      })) ||
    compose;

  const store = createStore(
    resettableAppReducer,
    { /* set your initial state here */},
    composeEnhancers(
      applyMiddleware(
        sagaMiddleware,
        routerMiddleware(history),
      ),
      // add your own enhancers here
    ),
  );

  sagaMiddleware.run(saga);
  return store;
};

export const history = createBrowserHistory();
export const authProvider = () => Promise.resolve();
export const dataProvider = jsonServerProvider(process.env.REACT_APP_API_URL, fetchJson);
