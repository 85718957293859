import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { Spinner } from "reactstrap";
import MetaTags from "react-meta-tags";
import { Card, CardBody, Col, Container, Row, Button } from "reactstrap";
import Flatpickr from "react-flatpickr";
import toastr from "toastr";
import { useHistory } from "react-router";
import api from "api_helper";

//import Charts
import PieChartData from "./PieChartData";
import ScatterChart from "./ScatterChart";

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";

//i18n
import { withTranslation } from "react-i18next";

//redux
import i18n from "i18n";
import BarChart from "./BarChart";
import Select from "react-select";

const Dashboard = props => {
  const [chartData1, setChartData1] = useState(false);
  const [chartData2, setChartData2] = useState(false);
  const [chartData3, setChartData3] = useState(false);
  const [chartData4, setChartData4] = useState(false);
  const [chartData5, setChartData5] = useState(false);

  const [loading1, setLoading1] = useState(false);
  const [loading2, setLoading2] = useState(false);
  const [loading3, setLoading3] = useState(false);
  const [loading4, setLoading4] = useState(false);
  const [loading5, setLoading5] = useState(false);

  const [filter1, setFilter1] = useState("");
  const [filter3, setFilter3] = useState("");
  const [filter4, setFilter4] = useState("");
  const [filter5, setFilter5] = useState("");
  const [stageId, setStageId] = useState(null);
  const [stages, setStages] = useState([]);

  const [id, setId] = useState("");
  const [time, setTime] = useState("");
  const history = useHistory();

  const handleStageFilter = e => {
    setStageId(e);
    setId("5");
  };

  const handleChange = async (date, dateStr, { config }) => {
    setId(config.id);
    switch (config.id) {
      case "1":
        setFilter1(dateStr);
        break;
      case "3":
        setFilter3(dateStr);
        break;
      case "4":
        setFilter4(dateStr);
        break;
      case "5":
        setFilter5(dateStr);
        break;
    }
  };

  const handleSubmit = async () => {
    let Date;
    switch (id) {
      case "1":
        Date = filter1.split("to");
        setLoading1(true);
        try {
          const res = await api.get(
            `/dashboard/items-supply?min_items_supply=${Date[0].trim()}&max_items_supply=${Date[1].trim()}`
          );
          setChartData1(res.data);
        } catch (error) {
          setLoading1(false);
        }
        setLoading1(false);
        setId("");
        break;

      case "3":
        Date = filter3.split("to");
        setLoading3(true);
        try {
          const res = await api.get(
            `/dashboard/orders-fulfillment?min_orders_fulfillment=${Date[0].trim()}&max_orders_fulfillment=${Date[1].trim()}`
          );
          setChartData3(res.data);
        } catch (error) {
          setLoading3(false);
        }
        setLoading3(false);
        setId("");
        break;
      case "4":
        Date = filter4.split("to");
        setLoading4(true);
        try {
          const res = await api.get(
            `/dashboard/suspended-orders?min_suspended_orders=${Date[0].trim()}&max_suspended_orders=${Date[1].trim()}`
          );
          setChartData4(res.data);
        } catch (error) {
          setLoading4(false);
        }
        setLoading4(false);
        setId("");
        break;
      case "5":
        Date = filter5.split("to");
        let url = "";
        
        if(Date[0])
          url = `/dashboard/blocked-orders?min_blocked_orders=${Date && Date[0].trim()}&max_blocked_orders=${Date && Date[1].trim()}&stage_id=${stageId?.value}`
        else 
          url = `/dashboard/blocked-orders?stage_id=${stageId?.value}`

          
          setLoading5(true);
        try {
          const res = await api.get(url);
          setChartData5(res.data);
        } catch (error) {
          setLoading5(false);
        }
        setLoading5(false);
        setId("");
        break;
      default:
        break;
    }
  };

  useEffect(async () => {
    setLoading1(true);
    setLoading2(true);
    setLoading3(true);
    setLoading4(true);
    setLoading5(true);

    const res1 = await api.get("/dashboard/items-supply");
    setChartData1(res1 && res1.data);

    const res2 = await api.get("/dashboard/orders-fulfillment");
    setChartData3(res2 && res2.data);

    const res3 = await api.get("/dashboard/suspended-orders");
    setChartData4(res3 && res3.data);

    const res4 = await api.get("/dashboard/blocked-orders");
    setChartData5(res4 && res4.data);

    const res5 = await api.get("/stages");
    let stages = [];
    res5 &&
      res5.data.map(e => {
        stages.push({
          value: e.id,
          label: e.name,
        });
      });
    setStages(stages);

    setId("");
    setLoading1(false);
    setLoading2(false);
    setLoading3(false);
    setLoading4(false);
    setLoading5(false);
  }, []);

  const handleReset = async id => {
    switch (id) {
      case "1":
        setLoading1(true);
        const res = await api.get(`/dashboard/items-suppl`);
        setChartData1(res && res.data);
        setLoading1(false);
        setFilter1("")
        setId("");
        break;

      case "3":
        setLoading3(true);
        const res1 = await api.get(`/dashboard/orders-fulfillment`);
        setChartData3(res1 && res1.data);
        setLoading3(false);
        setFilter3("")
        setId("");
        break;

      case "4":
        setLoading4(true);
        const res2 = await api.get(`/dashboard/suspended-orders`);
        setChartData4(res2 && res2.data);
        setLoading4(false);
        setFilter4("")
        setId("");
        break;

      case "5":
        setLoading5(true);
        const res3 = await api.get(`/dashboard/blocked-orders`);
        setChartData5(res3 && res3.data);
        setLoading5(false);
        setFilter5("")
        setStageId(null)
        setId("");
        break;
      default:
        break;
    }
  };
  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>{i18n.t("dashboard")}</title>
        </MetaTags>
        <Container fluid>
          {/* Render Breadcrumb */}
          <Breadcrumbs
            title={i18n.t("dashboards")}
            breadcrumbItem={i18n.t("dashboard")}
          />

          <Row>
            <Col xl="6">
              {!loading1 ? (
                <div>
                  <Card>
                    <CardBody>
                      <div className="d-flex justify-content-between align-items-center">
                        <Flatpickr
                          placeholder="dd-mm-yyyy"
                          className="form-control d-block"
                          value={filter1}
                          onChange={handleChange}
                          options={{
                            id: "1",
                            mode: "range",
                            dateFormat: "Y-m-d",
                          }}
                        />

                        <Button
                          type="submit"
                          style={{ marginRight: "10px", marginLeft: "10px" }}
                          color="primary"
                          onClick={handleSubmit}
                        >
                          {i18n.t("submit")}
                        </Button>
                        <Button
                          type="reset"
                          style={{ marginRight: "10px", marginLeft: "10px" }}
                          color="danger"
                          onClick={() => handleReset("1")}
                        >
                          {i18n.t("cancel")}
                        </Button>
                      </div>
                    </CardBody>
                  </Card>
                  <Card>
                    <CardBody>
                      <ScatterChart
                        data={chartData1}
                        title={`${i18n.t("items_supply_average")}`}
                      />
                    </CardBody>
                  </Card>
                </div>
              ) : (
                <Card>
                  <CardBody>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <Spinner color="primary" />
                    </div>
                  </CardBody>
                </Card>
              )}
            </Col>
            <Col xl="6">
              {!loading2 ? (
                <div>
                  <Card>
                    <CardBody>
                      <div>
                        <PieChartData
                          title={i18n.t("items_consumption")}
                          data={chartData2}
                        />
                      </div>
                    </CardBody>
                  </Card>
                </div>
              ) : (
                <Card>
                  <CardBody>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <Spinner color="primary" />
                    </div>
                  </CardBody>
                </Card>
              )}
            </Col>
          </Row>

          <Row>
            <Col xl="6">
              {!loading3 ? (
                <div>
                  <Card>
                    <CardBody>
                      <div className="d-flex justify-content-between align-items-center">
                        <Flatpickr
                          id="3"
                          value={filter3}
                          placeholder="dd-mm-yyyy"
                          className="form-control d-block"
                          onChange={handleChange}
                          options={{
                            mode: "range",
                            id: "3",
                            dateFormat: "Y-m-d",
                          }}
                        />
                        <Button
                          type="submit"
                          style={{ marginRight: "10px", marginLeft: "10px" }}
                          color="primary"
                          onClick={handleSubmit}
                        >
                          {i18n.t("submit")}
                        </Button>
                        <Button
                          type="reset"
                          style={{ marginRight: "10px", marginLeft: "10px" }}
                          color="danger"
                          onClick={() => handleReset("3")}
                        >
                          {i18n.t("cancel")}
                        </Button>
                      </div>
                    </CardBody>
                  </Card>
                  <Card>
                    <CardBody>
                      <ScatterChart
                        data={chartData3}
                        title={`${i18n.t("orders_fulfillment_average")}`}
                      />
                    </CardBody>
                  </Card>
                </div>
              ) : (
                <Card>
                  <CardBody>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <Spinner color="primary" />
                    </div>
                  </CardBody>
                </Card>
              )}
            </Col>
            <Col xl="6">
              {!loading4 ? (
                <div>
                  <Card>
                    <CardBody>
                      <div className="d-flex justify-content-between align-items-center">
                        <Flatpickr
                          value={filter4}
                          placeholder="dd-mm-yyyy"
                          className="form-control d-block"
                          onChange={handleChange}
                          options={{
                            id: "4",
                            mode: "range",
                            dateFormat: "Y-m-d",
                          }}
                        />

                        <Button
                          onClick={handleSubmit}
                          type="submit"
                          style={{ marginRight: "10px", marginLeft: "10px" }}
                          color="primary"
                        >
                          {i18n.t("submit")}
                        </Button>
                        <Button
                          type="reset"
                          style={{ marginRight: "10px", marginLeft: "10px" }}
                          color="danger"
                          onClick={() => handleReset("4")}
                        >
                          {i18n.t("cancel")}
                        </Button>
                      </div>
                    </CardBody>
                  </Card>
                  <Card>
                    <CardBody>
                      <BarChart
                        dataTitle="suspended_orders"
                        data={chartData4}
                        title={`${i18n.t("suspended_orders")}`}
                      />
                    </CardBody>
                  </Card>
                </div>
              ) : (
                <Card>
                  <CardBody>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <Spinner color="primary" />
                    </div>
                  </CardBody>
                </Card>
              )}
            </Col>
          </Row>
          <Row>
            <Col xl="12">
              {!loading5 ? (
                <div>
                  <Card>
                    <CardBody>
                      <div className="d-flex justify-content-between align-items-center">
                        <Flatpickr
                          value={filter5}
                          placeholder="dd-mm-yyyy"
                          className="form-control d-block"
                          onChange={handleChange}
                          options={{
                            id: "5",
                            mode: "range",
                            dateFormat: "Y-m-d",
                          }}
                        />

                        <div style={{ width: "100%" }}>
                          <Select
                            placeholder="Select Stage"
                            options={stages}
                            value={stageId}
                            className="m-2  d-block"
                            onChange={handleStageFilter}
                          />
                        </div>
                        <Button
                          onClick={handleSubmit}
                          style={{ marginRight: "10px", marginLeft: "10px" }}
                          color="primary"
                        >
                          {i18n.t("submit")}
                        </Button>
                        <Button
                          type="reset"
                          style={{ marginRight: "10px", marginLeft: "10px" }}
                          color="danger"
                          onClick={() => handleReset("5")}
                        >
                          {i18n.t("cancel")}
                        </Button>
                      </div>
                    </CardBody>
                  </Card>
                  <Card>
                    <CardBody>
                      <BarChart
                        data={chartData5}
                        dataTitle="blocked_orders"
                        title={`${i18n.t("blocked_orders")}`}
                      />
                    </CardBody>
                  </Card>
                </div>
              ) : (
                <Card>
                  <CardBody>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <Spinner color="primary" />
                    </div>
                  </CardBody>
                </Card>
              )}
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

Dashboard.propTypes = {
  t: PropTypes.any,
  chartsData: PropTypes.any,
  onGetChartsData: PropTypes.func,
};

export default withTranslation()(Dashboard);
