import React from "react";
import ReactApexChart from "react-apexcharts";
import i18n from "i18n";

const ScatterChart = ({ data, title }) => {
  const series = [];
  const labels = [];
  data &&
    data?.map(e => {
      series.push(parseFloat(e.avg.toFixed(2)));
      labels.push(
        new Date(e.from).toLocaleDateString() +
          " - " +
          new Date(e.to).toLocaleDateString()
      );
    });

  var state = {
    chart: {
      height: 320,
      type: "line",
    },
    stroke: {
      curve: "smooth",
      width: 2,
    },
    colors: ["#3F51B5", "#2196F3"],
    series: [
      {
        name: i18n.t("items"),
        data: series,
      },
    ],
    title: {
      text: i18n.t(title),
      align: "center",
      offsetY: 0,
      offsetX: 0,
    },

    markers: {
      size: 6,
      strokeWidth: 0,
      hover: {
        size: 9,
      },
    },
    grid: {
      show: true,
      padding: {
        bottom: 0,
      },
    },
    labels,
    tooltip: {
      x:{
       formatter: (e,value)=>{
          return (labels[value.dataPointIndex])
        }
      }
    },
    xaxis: {
      labels: {
        rotate: localStorage.I18N_LANGUAGE == 'ar' ? 30 : -30,
        formatter: function (val, opt) {
          return val && val.split(" - ")[0] ;
        },
        enabled: true,
      },
    },

    legend: {
      position: "top",
      horizontalAlign: "right",
      offsetY: -20,
    },
  };

  return (
    <React.Fragment>
      <ReactApexChart
        options={state}
        chart={state.chart}
        series={state.series}
        type="line"
        height={345}
      />
    </React.Fragment>
  );
};

export default ScatterChart;
