import * as Yup from "yup";
import i18n from "i18n";
import classNames from "classnames";
import React from "react";

export const createFields = [
    {
        source: 'name',
        label: i18n.t('name'),
        validation: Yup.string().required(i18n.t("required_field")).max(50, i18n.t('too_long')),
    },
    {
        source: 'email',
        label: i18n.t('email'),
        validation: Yup.string(),
    },
    {
        source: 'password',
        label: i18n.t('password'),
        component: 'input',
        type: 'password',
        validation: Yup.string().required(i18n.t("required_field")).max(150, i18n.t('too_long')),
    },
    {
        source: 'image',
        label: i18n.t('image'),
        type: 'file',
        component: 'file',
    },
    {
        source: 'role_id',
        component: 'select',
        label: i18n.t('role'),
        type: 'remote',
        resource: 'roles',
        optionLabel: 'role_id',
        optionValue: 'id',
        validation: Yup.string().required(i18n.t("required_field")),
    },
];

export const editFields = [{
    source: 'name',
    label: i18n.t('name'),
    validation: Yup.string().max(50, i18n.t('too_long')),
},
    {
        source: 'email',
        label: i18n.t('email'),
        validation: Yup.string(),
        disabled: true,
    },
    {
        source: 'new_password',
        label: i18n.t('new_password'),
        component: 'input',
        type: 'password',
        validation: Yup.string(),
    },
    {
        source: 'image',
        label: i18n.t('image'),
        type: 'file',
        component: 'file',
    },
    {
        source: 'role_id',
        component: 'select',
        label: i18n.t('role'),
        type: 'remote',
        resource: 'roles',
        optionLabel: 'role_id',
        optionValue: 'id',
        initialValue: ({role: {id}}) => id,
        validation: Yup.number(),
    },
];

export const columns = [
    {source: 'name', label: i18n.t('name'), sortable: true},
    {source: 'email', label: i18n.t('email'), sortable: true},
    {source: 'role.name', label: i18n.t('role'), sortable: true},
];


export const ViewOne = [
    {source: 'name', label: i18n.t('name')},
    {source: 'email', label: i18n.t('email')},
    {source: 'role', component: 'object', target: "name", label: i18n.t('role')},
    {source: 'image', label: i18n.t('image'), component: 'image'},
    {
        source: 'tasks',
        count: "total_tasks",
        label: i18n.t('tasks'),
        component: 'table',
        direction: 'column',
        columns: [
            {source: "name", text: i18n.t("name"), dataField: "name"},
            {
                label: i18n.t("description"),
                source: "description",
                text: i18n.t("description"),
                dataField: "description",
            },
            {
                label: i18n.t("feedback"),
                source: "feedback",
                text: i18n.t("feedback"), dataField: "feedback"},
            {
                label: i18n.t("manager"),
                source: "manager_name",
                text: i18n.t("manager"), dataField: "manager_name"},
            {
                label: i18n.t("stage_name"),
                source: "stage_name",
                text: i18n.t("stage_name"),
                dataField: "stage_name",
            },
            {
                label: i18n.t("status"),
                source: "status",
                text: i18n.t("status"),
                dataField: "status",
                formatter: value => (
                    <div
                        className={classNames(
                            "badge rounded-pill badge-soft-secondary text-white font-size-12",
                            {
                                "bg-danger": value === "blocked",
                                "bg-warning": value === "waiting",
                                "bg-primary": value === "in progress",
                                "bg-success": value === "done",
                            }
                        )}
                    >
                        {i18n.t(value)}
                    </div>
                ),
            },
            {
                label: i18n.t("start_at"),
                source: "start_at",
                text: i18n.t("start_at"), dataField: "start_at"
            },
            {
                label: i18n.t("end_at"),
                source: "end_at",
                text: i18n.t("end_at"), dataField: "end_at"
            },
        ],
    }
]

export const filters = [];

if (localStorage?.user !== undefined && JSON?.parse(localStorage?.user)?.role === 'admin') {
    filters.push({
            source: 'role_id',
            component: 'select',
            label: i18n.t('role'),
            type: 'remote',
            resource: 'roles',
            optionLabel: 'role_id',
            optionValue: 'id',
            container: 'filters',
            direction: 'column',
            validation: Yup.string().required(),
        },
    )
}

export const [title, subtitle] = [
    i18n.t("users"),
];