import { required, SimpleForm, TextField, TextInput, } from "react-admin";

import RichTextInput from "ra-input-rich-text";
import React from "react";

import { Create, Edit, List, View } from "components/CRUD";
import { DataTable } from "components/Tables";
import FormBuilder from "components/FormBuilder";
import { editFields, createFields, subtitle, filters, columns, ViewOne } from "./roles.config";
import i18n from "i18n";
import ViewMaker from "components/FormBuilder/ViewMaker";

export const RolesList = props => {
    return (
        <List {...props} filters={filters}>
            {({ data, ...rest }) => {
              
                return <DataTable resource={props.resource} data={data} columns={columns} {...rest} />
            }

            }
        </List>
    )
}

export const RolesCreate = props => {
    return (
        <Create {...props}>
            {(data) => <FormBuilder fields={createFields} {...props} {...data} />}
        </Create>
    );
}

export const RolesEdit = (props) => (
    <Edit title={i18n.t("edit")} {...props}>
        {(data) => <FormBuilder fields={editFields} {...props} {...data} />}
    </Edit>
);

export const RolesView = (props) => (
    <View title={i18n.t("view")} resource={props.resource} subtitle={subtitle} url="/roles" {...props}>
        {(props) => <ViewMaker fields={ViewOne} {...props}/>}
    </View>
);