import i18n from "i18n";
import * as Yup from "yup";

export const ViewOne = [
    {source: 'id', label: i18n.t('id'),},
    {source: 'name', label: i18n.t('name'),},
    {source: 'feedback', label: i18n.t('feedback'),},
    {source: 'status', label: i18n.t('status'),},
    {source: 'manager_name', label: i18n.t('manger'),},
    {source: 'stage_name', label: i18n.t('stage_name'),},
    {source: 'start_at', component: 'date', label: i18n.t('start_at'),},
    {source: 'end_at', component: 'date', label: i18n.t('end_at'),},
    {source: 'manufacture_order', component: 'object', target: 'id', label: i18n.t('manufacture_order'),},
]

export const filters = []

if (localStorage?.permissions?.includes("list_all_stages")) {
    filters.push({
        source: "stage_id",
        label: i18n.t("stage"),
        component: "select",
        type: "remote",
        resource: "stages",
        optionLabel: "stage_id",
        optionValue: "id",
        initialValue: ({stage_id}) => stage_id,
        container: "filters",
        direction: "column",
        validation: Yup.number().required(),
    });
}
