import React, {useState} from "react"
import PropTypes from 'prop-types'
import {Link} from "react-router-dom"
import {Dropdown, DropdownToggle, DropdownMenu, Row, Col, Button} from "reactstrap"
import SimpleBar from "simplebar-react"

//Import images
import {Container, Spinner, Alert} from "reactstrap"

//i18n
import {withTranslation} from "react-i18next"
import i18n from "i18n"
import useAxios from "axios-hooks";
import api from "../../../api_helper";
import {max} from "lodash";



const NotificationDropdown = props => {
    // Declare a new state variable, which we'll call "menu"

    const perPage = 10;
    const [take, setTake] = useState(perPage);
    const [skip, setSkip] = useState(0);
    const [menu, setMenu] = useState(false)

    const {t} = props;
    const urlParams = '?' + 'take=' + (take > 150 ? 150 : take) + '&skip=' + skip;
    const [{data: _data, loading, error}, refetch] = useAxios({
        url: process.env.REACT_APP_API_URL + '/notifications' + urlParams, method: 'GET',
        headers: {
            'Authorization': `Bearer ${localStorage.token}`,
            'Access-Control-Allow-Origin': 'no-cors',
        }
    });

    const handleViewMore = () => {
        setTake(max([take + perPage, 100]));
    }

    const renderSince = (since) => {
        let s = '';
        if (since.duration > 1)
            s = 's';

        return t("since") + " " + since.duration + " " + t(since.unit + s);
    }

    const renderData = () => {
        if (loading)
            return (<div style={{display: "flex", justifyContent: "center", alignItems: "center"}}>
                <Spinner color="primary"/>
            </div>)

        if (error)
            return <Alert color="danger">{i18n.t(error.message)}</Alert>

        const {data} = _data;
        return data.map((item, index) => {
            return (
                <Link key={index} to={`/${item.resource}/${item.element_id}/show`}
                      className="text-reset notification-item">
                    <div className="media">
                        <div className="avatar-xs me-3">
                            <span className="avatar-title bg-primary rounded-circle font-size-16">
                                <i className="bx bx-notification"/>
                            </span>
                        </div>
                        <div className="media-body">
                            <h6 className="mt-0 mb-1">
                                {i18n.t(item.description)}
                            </h6>
                            <div className="font-size-12 text-muted">
                                <p className="mb-0">
                                    <i className="mdi mdi-clock-outline"/>{" "}
                                    {renderSince(item.since)}
                                </p>
                            </div>
                        </div>
                    </div>
                </Link>
            );
        });
    }


    const handleClickToggle = e => {
        api.post('/notifications/read' + urlParams, []).then(e => {
            refetch()
        });
    }

    return (
        <React.Fragment>
            <Dropdown
                isOpen={menu}
                toggle={() => setMenu(!menu)}
                className="dropdown d-inline-block"
                style={{maxHeight: '280px'}}
                tag="li"
            >
                <DropdownToggle
                    className="btn header-item noti-icon "
                    tag="button"
                    disabled={loading}
                    id="page-header-notifications-dropdown"
                    onClick={handleClickToggle}
                >
                    <i className="bx bx-bell bx-tada"/>
                    {!loading && !error && <span
                        className="badge bg-danger rounded-pill">{_data?.count !== 0 ? _data?.count <= 20 ? _data?.count : "+20" : null}</span>}
                </DropdownToggle>

                <DropdownMenu className="dropdown-menu dropdown-menu-lg p-0 dropdown-menu-end">
                    <div className="p-3">
                        <Row className="align-items-center">
                            <Col>
                                <h6 className="m-0"> {i18n.t("notifications")} </h6>
                            </Col>
                        </Row>
                    </div>

                    <SimpleBar style={{maxHeight: '280px'}}>
                        {renderData()}
                    </SimpleBar>
                    <div className="p-2 border-top d-grid">
                        <Link
                            className="btn btn-sm btn-link font-size-14 btn-block text-center"
                            to="#"
                            onClick={handleViewMore}
                        >
                            <i className="mdi mdi-arrow-right-circle me-1"></i>
                            {i18n.t("view_more")}
                        </Link>
                    </div>
                </DropdownMenu>
            </Dropdown>
        </React.Fragment>
    )
}

export default withTranslation()(NotificationDropdown)

NotificationDropdown.propTypes = {
    t: PropTypes.any
}