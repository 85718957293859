import { Slider, } from '@material-ui/core'
import { InputGroup } from "reactstrap"

const InputRange = ({ source, value, touched, error, type, disable, onChange, defaultValue }) => {


    function valuetext(value) {
        return `${value}%`;
    }
    if (typeof value === 'number') {
        onChange(defaultValue)
    }

    return (
        <div>
            <div style={{ display: "flex", alignItems: "center" }}>

                <span className="input-group-text">{value[0]}%</span>
                <Slider
                    style={{ margin: "0px 5px" }}
                    value={value}
                    name={source}
                    onChange={(e, newValue) => typeof onChange === 'function' && onChange(newValue)}
                    valueLabelDisplay="auto"
                    aria-labelledby="range-slider"
                    getAriaValueText={valuetext}
                />
                <span className="input-group-text">{value[1]}%</span>

            </div>
        </div>
    )
}

export default InputRange
