import * as Yup from "yup";
import React from "react";
import i18n from "i18n";

export const createFields = [
    {
        source: 'products',
        label: i18n.t('products'),
        validation: Yup.array().required(i18n.t("required_field")).of(
            Yup.object().shape({
              id: Yup.number().typeError(i18n.t("this_field_must_be_a_number")).required(i18n.t("required_field")),
              quantity: Yup.number().typeError(i18n.t("this_field_must_be_a_number")).required(i18n.t("required_field")),
            })
          ),
        component: 'array',
        startWithOne: true,
        fields: [{
            source: 'id',
            label: i18n.t('product'),
            component: 'select',
            type: 'remote',
            resource: 'products',
            optionLabel: 'name',
            optionValue: 'id',
            initialValue: ({product: {id}}) => id,
        }, {
            source: 'quantity',
            label: i18n.t('quantity'),
        }],
    }
];

export const title = i18n.t("neediness_calculator");
export const subtitle = ""
