import createAdminStore, { dataProvider, history } from './createAdminStore';

import authProvider from "authProvider";


export const adminStore = createAdminStore({
    authProvider,
    dataProvider,
    history,
  });
  