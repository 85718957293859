import classes from "classnames";
import React from "react";
import "flatpickr/dist/themes/material_blue.css"
import Flatpickr from "react-flatpickr"
import moment from "moment"
const DateFormik = props => {
    const {source, value, touched, error, type, disabled, placeholder,onChange, defaultValue} = props;
    if(!value){
        const date = moment(new Date()).format("YYYY-MM-DD")
        onChange(date)
    }
    return (
        <Flatpickr
            id={source}
            placeholder={placeholder}
            name={source}
            value={value }
            type={type}
            disable={disabled}
            className={classes("form-control", {"is-touched": !!touched, "is-invalid": !!error})}
            defaultValue={defaultValue}
            onChange={(date, dateStr) => typeof onChange === 'function' && onChange(dateStr) && console.log(dateStr)}
            options={{
                altInput: true,
                defaultDate: 'today',
                altFormat: "F j, Y",
                dateFormat: "Y-m-d"
            }}
        />
    );
}

export default DateFormik;