import PropTypes from "prop-types";
import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

//i18n
import { withTranslation } from "react-i18next";
import SidebarContent from "./SidebarContent";

import { Link } from "react-router-dom";

import logo from "../../assets/images/atmatah.png";
import logoLightPng from "../../assets/images/atmatah.png";
import logoLightSvg from "../../assets/images/atmatah.png";
import logoDark from "../../assets/images/atmatah.png";
import i18n from "i18next";

const Sidebar = props => {
  const href = localStorage?.getItem("permissions")?.includes("view_dashboard")
    ? "/dashboard"
    :  "/profile";
  return (
    <React.Fragment>
      {localStorage?.token ? (
        <div className="vertical-menu">
          <div className="navbar-brand-box">
            <Link to={href} className="logo logo-dark">
              <span className="logo-sm">
                <img src={logo} alt="" height="22" />
              </span>
              <span className="logo-lg">
                <img src={logoDark} alt="" height="90" />
              </span>
            </Link>

            <Link to={href} className="logo logo-light">
              <span className="logo-sm">
                <img src={logoLightSvg} alt="" height="22" />
              </span>
              <span className="logo-lg ">
                <img src={logoLightPng} alt="" width="100" height="75" />
              </span>
            </Link>
          </div>
          <div data-simplebar className="h-100">
            <SidebarContent t={i18n.t} />
          </div>
          <div className="sidebar-background"></div>
        </div>
      ) : (
        <div></div>
      )}
    </React.Fragment>
  );
};

Sidebar.propTypes = {
  type: PropTypes.string,
};

const mapStatetoProps = state => {
  return {
    layout: state.Layout,
  };
};
export default connect(
  mapStatetoProps,
  {}
)(withRouter(withTranslation()(Sidebar)));
