import {
    GET_ITEMS_DATA,
    CLEAR_DATA
} from "./actionTypes";

export const getItemsData = (data) => ({
    type: GET_ITEMS_DATA,
    payload: data,
});

export const clearData = () => ({
    type: CLEAR_DATA,
})



